/**
 * @copyright 2021 @ DigiNet
 * @author DINHTHIEN
 * @create 29/1/2021
 * @Example
 */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import makeStyle from "diginet-core-ui/theme/make-styles";
import { Chart, Title, ValueAxis, LoadingIndicator } from "devextreme-react/chart";
import PieChart, { Legend, Series, Tooltip, Connector, Label } from "devextreme-react/pie-chart";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import html2canvas from "html2canvas";
import _ from "lodash";
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";
import Slider from "react-slick";
import {
    Avatar,
    Button,
    ButtonIcon,
    Col,
    DatePicker,
    Row,
    Typography,
    Card,
    Tooltip as TooltipU,
    Icon,
    ListItem,
    ListItemIcon,
    ListItemText,
    CardHeader,
    CardBody,
} from "diginet-core-ui/components";

import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import Api from "../../../../services/api";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W25F4000Actions from "../../../../redux/W2X/W25F4000/W25F4000_actions";
import { useTheme } from "diginet-core-ui/theme";

const coreTheme = useTheme();
const {
    colors: {
        system: { rest },
        fill: { extrabutton },
        semantic:{ info5 },
    },
} = coreTheme;

const useStyles = makeStyle(theme => {
    return {
        actionToolbar: {
            marginLeft: -15,
            marginRight: -15,
            "&.action-toolbar": {
                width: "auto",
                padding: "0px 24px",
            },
        },
        actionToolbarDatePicker: {
            maxWidth: 240,
        },
        rowContainer: {
            marginLeft: -9,
            marginBottom: -15,
            backgroundColor: extrabutton,
        },
        sliderStyle: {
            marginLeft: -8,
        },
    };
});
const color = {
    Received: "aqua5",
    Interviewed: "success5",
    Offered: "warning5",
    Hired: "violet5",
    Rejected: "danger5",
    Total: "blue",
};
const configColumns = [
    {
        dataField: "Received",
        color: color.Received,
    },

    {
        dataField: "Interviewed",
        color: color.Interviewed,
    },
    {
        dataField: "Offered",
        color: color.Offered,
    },
    {
        dataField: "Hired",
        color: color.Hired,
    },
    {
        dataField: "Rejected",
        color: color.Rejected,
    },
    {
        dataField: "Total",
        color: color.Total,
    },
];
const styleSlideCard = {
    slick: {
        position: "absolute",
        zIndex: 2,
        transform: "translateY(-50%)",
        top: "50%",
    },
};
const NextArrow = ({ onClick, currentSlide, slideCount }) => {
    return (
        <>
            {currentSlide <= Math.ceil(slideCount / settings.slidesToShow) && (
                <ButtonIcon
                    onClick={onClick}
                    circular
                    style={{
                        ...styleSlideCard.slick,
                        backgroundColor: rest,
                        right: "32px",
                    }}
                    name="ArrowRight"
                    size={"small"}
                    viewType={"text"}
                    color="white"
                />
            )}
        </>
    );
};
const PrevArrow = ({ onClick, currentSlide }) => {
    return (
        <>
            {currentSlide > 0 && (
                <ButtonIcon
                    onClick={onClick}
                    circular
                    style={{
                        ...styleSlideCard.slick,
                        backgroundColor: rest,
                        left: "32px",
                    }}
                    name="ArrowLeft"
                    size={"small"}
                    viewType={"text"}
                    color="white"
                />
            )}
        </>
    );
};
const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 6,
    slidesToScroll: 1,

    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false,
                dots: false,
                nextArrow: <NextArrow />,
                prevArrow: <PrevArrow />,
            },
        },
    ],
};

const W25F4000 = ({ generalActions, ...props }) => {
    const [dataRecruitmentInfo, setDataRecruitmentInfo] = useState([]);
    const [dataRecruitmentInfoByDay, setDataRecruitmentInfoByDay] = useState({});
    const [dataRecruitmentInfoByWeek, setDataRecruitmentInfoByWeek] = useState({});
    const [dataRecruitmentGrid, setDataRecruitmentGrid] = useState({});
    const [dataRecruitmentTotal, setDataRecruitmentTotal] = useState({});

    const [loadingRecInfo, setLoadingRecInfo] = useState(false);
    const [loadingRecInfoByDay, setLoadingRecInfoByDay] = useState(false);
    const [loadingRecInfoByWeek, setLoadingRecInfoByWeek] = useState(false);
    const [loadingRecGrid, setLoadingRecGrid] = useState(false);
    const [loadingRecTotalGrid, setLoadingRecTotalGrid] = useState(false);
    const [loadingPdf, setLoadingPDF] = useState(false);

    const [dateFrom, setDateFrom] = useState(moment().clone().startOf("month").format("YYYY-MM-DD"));
    const [dateTo, setDateTo] = useState(moment().clone().endOf("month").format("YYYY-MM-DD"));
    const [iPermission, setIPermission] = useState(0);

    const refGridRec = useRef(null);

    const classes = useStyles();

    useEffect(() => {
        let timerIDRecInfo = null;
        let timerIDRecInfoByDay = null;
        let timerIDRecInfoByWeek = null;
        let timerIDRecGrid = null;
        let timerIDRecTotalGrid = null;
        (async (DateFrom, DateTo) => {
            let params = { Language: Config.language || "84" };
            try {
                setLoading(0);

                timerIDRecInfo = await Api.put("/w25f4000/load-form", {
                    ...params,
                    Mode: 1,
                    DateFrom,
                    DateTo,
                })
                    .then(handleResponse)
                    .then(resData => {
                        if (!resData) return -1;
                        setDataRecruitmentInfo(
                            _.map(resData, (item, i) => ({
                                title: _.get(item, "TypeName", ""),
                                isRecInfo: true,
                                description: _.get(item, "Description", ""),
                                action: {
                                    title: getTitleDes(i),
                                    number: _.get(item, "Number", 0),
                                },
                                dataContent: _.get(item, "detail", []),
                            }))
                        );
                        return 1;
                    })
                    .then(setLoading);

                timerIDRecInfoByDay = await Api.put("/w25f4000/load-form", {
                    ...params,
                    Mode: 2,
                    DateFrom,
                    DateTo,
                })
                    .then(handleResponse)
                    .then(resData => {
                        if (!resData) return -2;
                        setDataRecruitmentInfoByDay({
                            title: Config.lang("Tong_hop_ung_vien_moi_theo_ngay"),
                            dataContent: {
                                dataSources: _.map(resData, item => ({
                                    ...item,
                                    DateRec: moment.utc(_.get(item, "DateRec", "")).format("DD/MM/YYYY"),
                                })),
                                dataSeries: [
                                    {
                                        valueField: "NumCanNumber",
                                        argumentField: "DateRec",
                                        name: "Total",
                                        type: "bar",
                                        color: info5,
                                    },
                                ],
                            },
                        });
                        return 2;
                    })
                    .then(setLoading);

                timerIDRecInfoByWeek = await Api.put("/w25f4000/load-form", {
                    ...params,
                    Mode: 3,
                    DateFrom,
                    DateTo,
                })
                    .then(handleResponse)
                    .then(resData => {
                        if (!resData) return -3;
                        let tempPeriod = _.get(resData, "[0]Period", "");
                        let tempIndex = 0;

                        setDataRecruitmentInfoByWeek({
                            title: Config.lang("Tong_hop_ung_vien_moi_theo_tuan"),
                            dataContent: {
                                dataSources: _.reduce(
                                    resData,
                                    (acc, currItem) => {
                                        if (currItem.Period !== tempPeriod) {
                                            tempIndex++;
                                            tempPeriod = currItem.Period;
                                        }
                                        acc[tempIndex] = {
                                            ...acc[tempIndex],
                                            Period: currItem.Period,
                                            [`NumCan${currItem.TypeID}`]: currItem.NumCan,
                                        };
                                        return acc;
                                    },
                                    []
                                ),
                                dataSeries: _.chain(resData)
                                    .map(item => item.TypeID)
                                    .uniq()
                                    .map(typeID => ({
                                        valueField: `NumCan${typeID}`,
                                        argumentField: "Period",
                                        name: typeID,
                                        type: "bar",
                                        color: coreTheme?.colors?.[color[typeID]],
                                    }))
                                    .value(),
                            },
                        });
                        return 3;
                    })
                    .then(setLoading);

                timerIDRecGrid = await Api.put("/w25f4000/load-form", {
                    ...params,
                    Mode: 4,
                    DateFrom,
                    DateTo,
                })
                    .then(handleResponse)
                    .then(resData => {
                        if (!resData) return -4;
                        setDataRecruitmentGrid({
                            title: Config.lang("Tin_tuyen_dung"),
                            dataContent: resData.map(d => ({
                                ...d,
                                Received: d.Received ?? 0,
                                Interviewed: d.Interviewed ?? 0,
                                Offered: d.Offered ?? 0,
                                Hired: d.Hired ?? 0,
                                Rejected: d.Rejected ?? 0,
                                Total: d.Total ?? 0,
                            })),
                        });
                        return 4;
                    })
                    .then(setLoading);

                timerIDRecTotalGrid = await Api.put("/w25f4000/load-form", {
                    ...params,
                    Mode: 5,
                    DateFrom,
                    DateTo,
                })
                    .then(handleResponse)
                    .then(resData => {
                        if (!resData) return -5;
                        setDataRecruitmentTotal({
                            title: Config.lang("Tong_hop_ung_vien_theo_nguon_tuyen"),
                            dataContent: {
                                dataSources: resData.map(item => ({
                                    ...item,
                                    Interviewed: item.Interviewed ?? "",
                                    Hired: item.Hired ?? "",
                                    Offered: item.Offered ?? "",
                                })),
                            },
                        });
                        return 5;
                    })
                    .then(setLoading);
            } catch (e) {
                console.log(e);
            }
        })(dateFrom, dateTo);
        return () => {
            if (timerIDRecInfo) clearTimeout(timerIDRecInfo);
            if (timerIDRecInfoByDay) clearTimeout(timerIDRecInfoByDay);
            if (timerIDRecInfoByWeek) clearTimeout(timerIDRecInfoByWeek);
            if (timerIDRecGrid) clearTimeout(timerIDRecGrid);
            if (timerIDRecTotalGrid) clearTimeout(timerIDRecTotalGrid);
        };
    }, [dateFrom, dateTo]);

    useEffect(() => {
        (async () => {
            await generalActions.getPermission(
                "W25F4000",
                isPer => {
                    setIPermission(isPer);
                },
                true
            );
        })();
    }, [generalActions]);

    const onPointHoverChanged = ({ target: point }) => {
        point.showTooltip();
    };

    const getTitleDes = num => {
        switch (num) {
            case 1:
                return Config.lang("Da_nhan_ho_so");
            case 2:
                return Config.lang("Da_dien_ra");
            default:
                return Config.lang("Ung_vien");
        }
    };
    const getStructure = columns => {
        const structure = [];
        columns.forEach(column => {
            structure.push({
                fieldName: column.dataField,
                caption: column.caption,
                visible: column.visible,
                width: Math.round(column.visibleWidth),
            });
        });
        return structure;
    };

    const onExportExcel = async () => {
        const dataSource = refGridRec.current.instance.option("dataSource");
        if (dataSource.length > 0) {
            const dataExport = [];
            const columnsGrid = refGridRec.current.instance.getVisibleColumns();
            const dtGrid = dataSource.map(item => ({
                ...item,
                CreateUserID: item?.CreateUserName ?? "",
                ListMember: item?.ListMemberName ?? "",
                Deadline: moment.utc(item.Deadline).isValid ? moment.utc(item?.Deadline).format("YYYY-MM-DD") : "",
            }));

            dataExport.push({
                title: Config.lang("Tin_tuyen_dung"),
                grid: dtGrid,
                structure: getStructure(columnsGrid),
            });

            const params = {
                titleForm: Config.lang("Tin_tuyen_dung"),
                filename: Config.lang("Tin_tuyen_dung"),
                data: JSON.stringify(dataExport),
            };
            props.w25f4000Actions.onExport(params, (error, dataFile) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    setLoading(false);
                    return false;
                }
                const link = document.createElement("a");
                link.href = dataFile?.URL ?? "";
                link.download = dataFile.fileName ? decodeURI(dataFile.fileName) : "";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                Config.notify.show("success", Config.lang("Xuat_du_lieu_thanh_cong"), 3000);
            });
        } else {
            Config.popup.show("INFO", Config.lang("No_data"));
        }
    };

    const onExportPDF = () => {
        const input = document.getElementById("containerToPrint");
        html2canvas(input, {
            scale: "0.5",
            allowTaint: true,
            useCORS: true,
            scrollX: 0,
            scrollY: -window.scrollY,
        }).then(canvas => {
            const imgData = canvas.toDataURL("image/png");
            if (canvas && imgData) {
                try {
                    setLoadingPDF(true);
                    const myWindow = window.open("", Config.lang("Danh_gia_nhan_vien"));
                    myWindow.document.write(
                        '<body onload="window.print();window.close();"><img src="' + imgData + '" /></body>'
                    );
                    myWindow.document.close();
                    myWindow.focus();
                    setLoadingPDF(false);
                } catch (e) {
                    setLoadingPDF(false);
                    Config.popup.show("INFO", Config.lang("Co_loi_xay_ra_trong_qua_trinh_xu_ly"));
                    return false;
                }
            } else {
                Config.popup.show("INFO", Config.lang("Dang_chuan_bi_du_lieu_Xin_vui_long_cho"));
                return false;
            }
        });
    };

    const handleResponse = res => {
        if (_.isEmpty(res)) return false;
        const resData = _.get(res, "data", []);
        const resMessageErr = _.get(res, "message", _.get(resData, "Message", ""));
        if (resMessageErr) {
            Config.popup.show("ERROR", resMessageErr);
            return false;
        }
        return resData;
    };

    const setLoading = mode => {
        switch (mode) {
            case 0:
                setLoadingRecInfo(true);
                setLoadingRecInfoByDay(true);
                setLoadingRecInfoByWeek(true);
                setLoadingRecGrid(true);
                setLoadingRecTotalGrid(true);
                return "";
            case 1:
                return setTimeout(() => setLoadingRecInfo(false), 300);
            case 2:
                return setTimeout(() => setLoadingRecInfoByDay(false), 300);
            case 3:
                return setTimeout(() => setLoadingRecInfoByWeek(false), 300);
            case 4:
                return setTimeout(() => setLoadingRecGrid(false), 300);
            case 5:
                return setTimeout(() => setLoadingRecTotalGrid(false), 300);
            case -1:
                setLoadingRecInfo(false);
                return false;
            case -2:
                setLoadingRecInfoByDay(false);
                return false;
            case -3:
                setLoadingRecInfoByWeek(false);
                return false;
            case -4:
                setLoadingRecGrid(false);
                return false;
            case -5:
                setLoadingRecTotalGrid(false);
                return false;
            default:
                return "";
        }
    };

    const handleChangeDateBox = (e, type) => {
        const value = _.get(e, "value", "");
        switch (type) {
            case "dateFrom":
                setDateFrom(value);
                break;
            case "dateTo":
                setDateTo(value);
                break;
            default:
                break;
        }
    };

    const renderBarChartContent = ({ dataSources = [], dataSeries = [] }) => {
        return (
            <>
                {loadingRecInfoByDay || loadingRecInfoByWeek ? (
                    <Skeleton variant="rect" width={"100%"} height={400} />
                ) : (
                    <Chart id="chart1" dataSource={dataSources}>
                        {_.map(dataSeries, (item, i) => (
                            <Series key={i} {...item} />
                        ))}
                        <Legend verticalAlignment="bottom" horizontalAlignment="center" />
                        <ValueAxis>
                            <Title text={Config.lang("So_luong_ung_vien")} />
                        </ValueAxis>
                        <LoadingIndicator enabled={true} />
                        <Tooltip enabled={true} />
                    </Chart>
                )}
            </>
        );
    };

    const getInfo = (user = {}) => {
        return {
            [Config.lang("Nhan_vien")]: `${user?.EmployeeID ?? ""} - ${user?.EmployeeName ?? ""}`,
            [Config.lang("Phong_ban")]: user?.DepartmentName ?? "",
            [Config.lang("Du_an")]: user?.ProjectName ?? "",
            [Config.lang("Chuc_vu")]: user?.DutyName ?? "",
            [Config.lang("Ngay_vao_lam")]: Config.convertDate(user?.DateJoined, "", "L") ?? "",
        };
    };

    const renderGridContent = dataGrid => {
        return (
            <GridContainer
                pagerFullScreen={false}
                reference={ref => (refGridRec.current = ref)}
                style={{ border: "none" }}
                dataSource={dataGrid || []}
                keyExpr={"RecInfoCode"}
                showBorders={false}
                loading={loadingRecGrid}
                rowAlternationEnabled={false}
                typePaging={"normal"}
                hoverStateEnabled={true}
                gridProps={{
                    onExporting: e => {
                        e.fileName = "Tin_tuyen_dung_" + new Date().getTime();
                    },
                }}
            >
                <Column caption={Config.lang("Ma")} dataField={"RecInfoCode"} alignment={"left"} />
                <Column caption={Config.lang("Thong_tin")} dataField={"RecInfoTitle"} alignment={"left"} />
                <Column
                    caption={Config.lang("Nguoi_tao")}
                    dataField={"CreateUserID"}
                    cellRender={({ data }) => {
                        const user = Config.getUser({ UserID: data["CreateUserID"] });
                        return (
                            <Avatar
                                width={32}
                                height={32}
                                data={getInfo(user)}
                                src={Config.getUserPicture(user?.UserPictureURL)}
                                readOnly
                                hoverAble
                            />
                        );
                    }}
                    alignment={"left"}
                />
                <Column
                    caption={Config.lang("Quan_ly")}
                    dataField={"ListMember"}
                    cellRender={({ data }) => (
                        <div style={{ display: "flex" }}>
                            {_.map(_.split(_.get(data, "ListMember", ""), ","), (UserID, i) => {
                                const user = Config.getUser({ EmployeeID: UserID || null });
                                return (
                                    <div key={i}>
                                        <Avatar
                                            className={"mgr5"}
                                            width={32}
                                            height={32}
                                            data={getInfo(user)}
                                            src={Config.getUserPicture(user?.UserPictureURL)}
                                            readOnly
                                            hoverAble
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    alignment={"left"}
                />
                <Column
                    caption={Config.lang("Ngay_het_han")}
                    dataField={"Deadline"}
                    alignment={"center"}
                    dataType={"date"}
                    format={"dd/MM/yyyy"}
                    cellRender={({ data }) => moment.utc(_.get(data, "Deadline", "")).format("DD/MM/YYYY")}
                />
                {configColumns.map(field => (
                    <Column
                        caption={Config.lang(field.dataField)}
                        dataField={field.dataField}
                        cellRender={({ data }) => (
                            <Typography type="h3" color={field.color}>
                                {_.get(data, field.dataField, 0)}
                            </Typography>
                        )}
                        alignment={"center"}
                    />
                ))}
            </GridContainer>
        );
    };

    const renderGridTotalContent = ({ dataSources }) => {
        return (
            <GridContainer
                pagerFullScreen={false}
                style={{ border: "none" }}
                dataSource={dataSources || []}
                showBorders={false}
                loading={loadingRecTotalGrid}
                rowAlternationEnabled={false}
                typePaging={"normal"}
                hoverStateEnabled={true}
            >
                <Column caption={Config.lang("Nguon")} dataField={"RecSourceName"} alignment={"left"} />
                <Column
                    caption={Config.lang("Interviewed")}
                    cellRender={({ data }) => (
                        <Typography type="h3" color={color.Interviewed}>
                            {_.get(data, "Interviewed", 0)}
                        </Typography>
                    )}
                    alignment={"center"}
                />
                <Column
                    caption={Config.lang("Hired")}
                    cellRender={({ data }) => (
                        <Typography type="h3" color={color.Hired}>
                            {_.get(data, "Hired", 0)}
                        </Typography>
                    )}
                    alignment={"center"}
                />
                <Column
                    caption={Config.lang("Offered")}
                    cellRender={({ data }) => (
                        <Typography type="h3" color={color.Offered}>
                            {_.get(data, "Offered", 0)}
                        </Typography>
                    )}
                    alignment={"center"}
                />
            </GridContainer>
        );
    };

    const renderGridAction = () => {
        return (
            <Button
                label={Config.lang("Tai_bao_cao_(excel)")}
                startIcon={"Download"}
                color={"primary"}
                viewType={"outlined"}
                disabled={loadingRecGrid || iPermission < 1}
                size={"medium"}
                onClick={onExportExcel}
            />
        );
    };

    const renderPieChartContent = ({ dataSources }) => {
        return (
            <>
                {loadingRecTotalGrid ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Skeleton variant={"circle"} width={350} height={350} />
                    </div>
                ) : (
                    <PieChart
                        id="pie"
                        palette="Bright"
                        onPointHoverChanged={onPointHoverChanged}
                        dataSource={dataSources}
                    >
                        <Series argumentField="RecSourceName" valueField="NumCan">
                            <Label
                                position="columns"
                                customizeText={pointInfo => `${pointInfo.valueText} (${pointInfo.percentText})`}
                                visible={true}
                            >
                                <Connector visible={true} width={1} />
                            </Label>
                        </Series>
                        <Tooltip
                            enabled={false}
                            customizeTooltip={pointInfo => ({ text: `${pointInfo.argumentText}` })}
                        />

                        <Legend verticalAlignment="bottom" horizontalAlignment="center" />
                    </PieChart>
                )}
            </>
        );
    };

    const renderSlideDataContent = (Value, ValueName, i) => (
        <ListItem key={i}>
            {loadingRecInfo ? (
                <>
                    <Skeleton variant="circle" width={15} height={15} />
                    <ListItemText>
                        <Skeleton variant="rect" width={100} height={10} />
                    </ListItemText>
                </>
            ) : (
                <div className="display_row">
                    <ListItemIcon name="Point" style={{ minWidth: "unset" }} className="mgr4x" />
                    <ListItemText>
                        <Typography type="p1" color="dark">
                            {Value} {ValueName}
                        </Typography>
                    </ListItemText>
                </div>
            )}
        </ListItem>
    );
    const renderSliderBody = (title, description, action) => (
        <>
            {loadingRecInfo ? (
                <>
                    <Skeleton variant="rect" width={60} height={20} />
                    <Skeleton variant="text" width={50} height={40} />
                </>
            ) : (
                <>
                    <div className="display_row">
                        <Typography
                            color={color[title]}
                            style={{
                                alignSelf: "center",
                            }}
                            type="h3"
                        >
                            {title}
                        </Typography>
                        <TooltipU direction="center" title={description}>
                            <Icon color={color[title]} name="Info" className="mgl2x" />
                        </TooltipU>
                        <Typography
                            color={color[title]}
                            style={{
                                marginLeft: "auto",
                            }}
                            type="t3"
                        >
                            {action.number}
                        </Typography>
                    </div>
                    <Typography
                        color="dark6"
                        style={{
                            marginLeft: "auto",
                        }}
                        type="p2"
                    >
                        {action.title}
                    </Typography>
                </>
            )}
        </>
    );

    const renderCard = (cardWidth = {}, dataCard = [], renderContent = () => "", renderAction = () => "") =>
        _.map(_.isPlainObject(dataCard) ? [dataCard] : dataCard, (item, i) => {
            return (
                <Col {...cardWidth}>
                    <Card
                        width="100%"
                        bodyStyle={{
                            gap: 16,
                            height: "100%",
                        }}
                        style={{
                            height: "100%",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                        }}
                    >
                        <CardHeader className="display_row align-between">
                            <>
                                {item.title ? (
                                    <div>
                                        <Typography color="dark" type="h3">
                                            {item.title}
                                        </Typography>
                                    </div>
                                ) : (
                                    <Skeleton variant="text" width={150} height={20}></Skeleton>
                                )}
                            </>
                            <div style={{ selfAlign: "right" }}>{renderAction && renderAction(item, i)}</div>
                        </CardHeader>
                        <CardBody>{renderContent(_.get(item, "dataContent", []))}</CardBody>
                    </Card>
                </Col>
            );
        });
    return (
        <>
            <ActionToolbar
                className={classes.actionToolbar}
                alignment="flex-end"
                title={Config.lang("Bao_cao_tuyen_dung")}
            >
                <div className={"display_row align-center mgr6x"}>
                    <Typography type="h6" color="dark8" className="mgr4x">
                        {Config.lang("Tu")}
                    </Typography>
                    <DatePicker
                        className={`${classes.actionToolbarDatePicker} mg0`}
                        max={dateTo}
                        actionIconAt={"end"}
                        viewType={"outlined"}
                        displayFormat={"DD/MM/YYYY"}
                        value={dateFrom}
                        onChange={e => handleChangeDateBox(e, "dateFrom")}
                    />
                </div>
                <div className={"display_row align-center"} style={{ margin: "12px 0" }}>
                    <Typography type="h6" color="dark8" className="mgr4x">
                        {Config.lang("Den")}
                    </Typography>
                    <DatePicker
                        className={`${classes.actionToolbarDatePicker} mg0`}
                        min={dateFrom}
                        actionIconAt={"end"}
                        viewType={"outlined"}
                        displayFormat={"DD/MM/YYYY"}
                        value={dateTo}
                        onChange={e => handleChangeDateBox(e, "dateTo")}
                    />
                </div>
                <div className="mgl6x" style={{ alignSelf: "center" }}>
                    <Button
                        disabled={loadingPdf}
                        label={Config.lang("Xuat_PDF")}
                        size={"medium"}
                        viewType={"outlined"}
                        color={"primary"}
                        startIcon={"Pdf"}
                        onClick={onExportPDF}
                    />
                </div>
            </ActionToolbar>
            {/* backGroundColor="dark11" */}
            <Row
                container
                id="containerToPrint"
                background="dark11"
                className={`pdt1x mgr0 pdr4x pdb4x ${classes.rowContainer}`}
            >
                <Col xs={12}>
                    <Slider {...settings} className={`${classes.sliderStyle}`}>
                        {dataRecruitmentInfo.map(({ title, action, dataContent, description }, i) => (
                            <div key={i} className={"pd2x"} style={{ minHeight: 200 }}>
                                <Card width="100%" style={{ boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)" }}>
                                    <CardHeader className="display_col">
                                        {renderSliderBody(title, description, action)}
                                    </CardHeader>

                                    <CardBody style={{ minHeight: 96 }}>
                                        {dataContent.map(({ Value, ValueName }, i) =>
                                            renderSlideDataContent(Value, ValueName, i)
                                        )}
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                    </Slider>
                </Col>
                {renderCard({ xs: 6 }, [dataRecruitmentInfoByDay, dataRecruitmentInfoByWeek], renderBarChartContent)}
                {renderCard({ xs: 12 }, dataRecruitmentGrid, renderGridContent, renderGridAction)}

                {renderCard({ xs: 8 }, dataRecruitmentTotal, renderGridTotalContent)}
                {renderCard({ xs: 4 }, dataRecruitmentTotal, renderPieChartContent)}
            </Row>
        </>
    );
};

export default compose(
    connect(
        state => ({
            iPermission: state.general.iPermission,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w25f4000Actions: bindActionCreators(W25F4000Actions, dispatch),
        })
    )
)(W25F4000);
