/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/24/2022
 * @Example
 */

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import Config from "../../../../config";
import GridContainer from "../../../grid-container/grid-container";

const initData = {
    rows: [],
    total: 0,
};
const GridCandidate = React.memo(props => {
    //init..
    const dispatch = useDispatch();

    //state variables..
    const {
        mode,
        dataFilter = {},
        dataSource: _dataSource,
        keyExpr,
        actionLoadGrid,
        typePaging,
        selected,
        GridProps,
        children,
        columns,
        onChangePage,
        onChangePerPage,
        onLoad,
        onRowValidating,
        onEditorPreparing,
        editing,
        onCellSelectionChanged,
        onEditingStart,
        onRowClick,
        setGridRef,
    } = props;
    const [dataGrid, setDataGrid] = useState(initData);
    const [dataGridLoading, setDataGridLoading] = useState(false);

    //refs variables..
    const filter = useRef({
        skip: 0,
        limit: 10,
        search: "",
        ...dataFilter,
    });
    const refGrid = useRef(null);
    const refSelectedRowKeys = useRef([]);
    const refSelectedRowData = useRef([]);
    const timerContentReady = useRef(null);

    const isRemotePaging = useMemo(() => typePaging === "remote", [typePaging]);
    //init functions..
    const _createEventParams = (obj = {}) => {
        return { cancel: false, ...obj };
    };

    useEffect(() => {
        filter.current = {
            ...filter.current,
            ...dataFilter,
        };
    }, [dataFilter]);

    useEffect(() => {
        if (selected) {
            if (_.isEmpty(selected)) {
                refSelectedRowKeys.current = [];
            } else {
                if (typeof selected[0] === "string") {
                    refSelectedRowKeys.current = selected;
                } else {
                    if (keyExpr) {
                        refSelectedRowKeys.current = selected.map(s => s[keyExpr]);
                    } else {
                        refSelectedRowKeys.current = selected;
                        refSelectedRowData.current = selected;
                    }
                }
            }
            refGrid.current.instance.selectRows([refSelectedRowKeys.current]);
            if (!_dataSource) {
                loadData("init");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const loadData = mode => {
        if (!actionLoadGrid) return false;
        const params = { ...filter.current, ...(dataFilter ? dataFilter : {}) };

        let event = _createEventParams({ mode, ...params });
        if (onLoad) onLoad(event);
        if (event.cancel || _dataSource) return false;

        setDataGridLoading(true);
        dispatch(
            actionLoadGrid(params, (error, data) => {
                setDataGridLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    setDataGrid({
                        rows: _.get(data, "rows", data || []),
                        total: _.get(data, "total", data.length || 0),
                    });
                }
            })
        );
    };

    const _onChangePage = page => {
        filter.current.skip = page * filter.current.limit;
        let event = _createEventParams({ ...filter.current });
        onChangePage && onChangePage(event);
        if (isRemotePaging && !event.cancel) loadData("changePage");
    };

    const _onChangePerPage = perPage => {
        filter.current.skip = 0;
        filter.current.limit = perPage;
        let event = _createEventParams({ ...filter.current });
        onChangePerPage && onChangePerPage(event);
        if (isRemotePaging && !event.cancel) loadData("changePerPage");
    };

    const _onSelectionChanged = e => {
        const { currentSelectedRowKeys, currentDeselectedRowKeys, selectedRowKeys, selectedRowsData } = e || {};
        if (
            mode === "single" &&
            currentSelectedRowKeys.length > 0 &&
            selectedRowKeys.length !== currentSelectedRowKeys.length
        ) {
            e.component.selectRows(currentSelectedRowKeys);
        }

        if (currentSelectedRowKeys && currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.map(item => {
                const selectedItemIndex = refGrid.current.instance.getRowIndexByKey(item);
                if (!refSelectedRowKeys.current.includes(item)) {
                    e.component.byKey(item).done(d => {
                        if (d.allowSelect === false) {
                            e.component.deselectRows([item]);
                        } else {
                            refSelectedRowKeys.current.push(item);
                            const rowData = selectedRowsData.find(d => d[keyExpr] === item);
                            if (rowData) refSelectedRowData.current.push(rowData);
                            refGrid.current.instance.cellValue(selectedItemIndex, "IsUsed", 1);
                            refGrid.current.instance.saveEditData();
                        }
                    });
                }
                return item;
            });
        }
        if (currentDeselectedRowKeys && currentDeselectedRowKeys.length > 0) {
            currentDeselectedRowKeys.map(item => {
                const deselectedItemIndex = refGrid.current.instance.getRowIndexByKey(item);
                if (e.component.byKey(item)) {
                    e.component.byKey(item).done(d => {
                        if (d.allowSelect !== false) {
                            refSelectedRowKeys.current = refSelectedRowKeys.current.filter(f => {
                                return item !== f;
                            });
                            refSelectedRowData.current = refSelectedRowData.current.filter(d => {
                                return item !== d[keyExpr];
                            });
                            refGrid.current.instance.cellValue(deselectedItemIndex, "IsUsed", 0);
                            refGrid.current.instance.saveEditData();
                        }
                    });
                }
                return item;
            });
            e.component.selectRows(refSelectedRowKeys.current);
        }
    };

    const renderDataGrid = useMemo(() => {
        let _data = _dataSource || dataGrid;
        _data = {
            rows: _data?.rows || _data || [],
            total: _data?.total || _data.length || 0,
        };
        return (
            <GridContainer
                reference={ref => {
                    refGrid.current = ref;
                    setGridRef(ref);
                }}
                dataSource={_.get(_data, "rows", [])}
                keyExpr={keyExpr}
                loading={dataGridLoading}
                hoverStateEnabled={true}
                rowAlternationEnabled={false}
                style={{ border: "none", marginTop: 12 }}
                height={400}
                pagerFullScreen={false}
                skipPerPage={filter.current.skip}
                itemPerPage={filter.current.limit}
                noDataText={Config.lang("No_data")}
                columnAutoWidth={true}
                allowColumnResizing={true}
                typeShort={window.innerWidth < 768}
                typePaging={typePaging}
                onCellSelectionChanged={onCellSelectionChanged}
                filterRow={{
                    visible: true,
                    showOperationChooser: false,
                }}
                sorting={{
                    mode: "none",
                }}
                selection={{
                    allowSelectAll: true,
                    mode: "multiple",
                    selectAllMode: "allPages",
                    showCheckBoxesMode: "always",
                }}
                onChangePage={_onChangePage}
                onRowClick={onRowClick}
                onChangePerPage={_onChangePerPage}
                onEditorPreparing={onEditorPreparing}
                onEditingStart={onEditingStart}
                editing={editing}
                totalItems={_.get(_data, "total", 0)}
                onContentReady={e => {
                    if (timerContentReady.current) clearTimeout(timerContentReady.current);
                    timerContentReady.current = setTimeout(() => {
                        e.component.selectRows(refSelectedRowKeys.current);
                    }, 300);
                }}
                onSelectionChanged={_onSelectionChanged}
                gridProps={{
                    ...(!children ? { columns: columns } : {}),
                    ...GridProps,
                }}
                onRowValidating={onRowValidating}
            >
                {children}
            </GridContainer>
        );
        // eslint-disable-next-line
    }, [_dataSource, dataGrid, mode, refSelectedRowKeys.current, dataGridLoading]);

    return <>{renderDataGrid}</>;
});

GridCandidate.propTypes = {
    FormID: PropTypes.string,
    typePaging: PropTypes.string,
    keyExpr: PropTypes.string.isRequired,
    dataFilter: PropTypes.object,
    dataSource: PropTypes.any,
    selected: PropTypes.array,
    columns: PropTypes.array,
    actionLoadGrid: PropTypes.any,
    GridProps: PropTypes.object,
    onLoad: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangePerPage: PropTypes.func,
};

export default GridCandidate;
