/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import * as W29F2006Actions from "../../../../redux/W2X/W29F2006/W29F2006_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup } from "react-bootstrap"
import ButtonGeneral from "../../../common/button/button-general";
import { MForm } from "../../../common/form-material";
import { TextInput, Dropdown, DatePicker, ButtonIcon } from 'diginet-core-ui/components';
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import GridContainer from "../../../grid-container/grid-container";
import ButtonCustom from "../../../common/button/button-custom";
import InlineSVG from "react-inlinesvg";
import moment from "moment";
import W09F6000 from "../../../W0X/W09/W09F6000/W09F6000";
import { browserHistory } from "react-router";
import { Tooltip } from 'devextreme-react/tooltip';
import { Column, RequiredRule } from "devextreme-react/data-grid";
import HelpIcon from '@material-ui/icons/Help';
import './w29f2006.scss';
import GridActionBar from "../../../grid-container/grid-actionbar";
import History                from "../../../libs/history";
import _ from "lodash";

class W29F2006 extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.state = {
            isPermission: 0,
            isSaving: false,
            isSaved: false,
            showEmployeeSelect: false,
            dataHandOver: [],
            dataShifts: [],
            dataAttendanceDetails: [],
            selectedRowKeys: [],
            dataCboProjects: {
                rows: [],
                total: 0
            },
            columns: [],

            dataForm: {
                AttendanceVoucherName: "",
                ProjectID: null,
                AttendanceDate: moment().format("YYYY-MM-DD"),
                HandOverID: "",
                MasterNote: ""
            },
            error: {
                AttendanceVoucherName: "",
                ProjectID: "",
                AttendanceDate: "",
                HandOverID: "",
            },
            gridLoading: false,
            projectLoading: false,
            defaultVisible: false,
            isShowTotalHours: false,
        };
        this.dataOldForm = {};
        this.dataOldAttendanceDetails = [];
        this.isRenderModalW09F6000 = false;
        this.filter = {
            limit: 10,
            skip: 0
        };
        this.filterCboProjects = {
            timer: null,
            skip: 0,
            limit: 50,
            strSearch: ""
        };
    }

    // async loadPermission() {
    //     await this.props.generalActions.getPermission("W29F2006", (isPer) => {
    //         this.setState({isPermission: isPer});
    //     });
    // };

    loadColumns = () => {
        this.props.w29f2006Actions.getColumns({}, (errors) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            // if (data && data.length > 0) {
            //     this.loadCboColumns(data);
            // }
        });
    };

    loadCoefficientColumn = () => {
        this.props.w29f2006Actions.getCoefficientColumn({}, errors => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        })
    };

    loadCboColumns = () => {
        const { dataForm } = this.state;
        const params = {
            Language: Config.language || "84",
            ProjectID: dataForm?.ProjectID || "",
            HandOverID: dataForm.HandOverID ? dataForm.HandOverID : "",
        };
        this.props.w29f2006Actions.getCboColumns(params, (errors) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    loadCboProjects = (isReset = false, projectID = "") => {
        const {skip, limit, strSearch} = this.filterCboProjects;
        const params = {
            HostID: "",
            FormID: "W29F2005",
            Language: Config.language || "84",
            skip,
            limit,
            search: projectID ? projectID : strSearch
        };
        this.setState({ projectLoading: true });
        this.props.generalActions.getCboProjects(params, (errors, data) => {
            this.setState({ projectLoading: false });
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (data) {
                const { dataCboProjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboHandOver = () => {
        const { dataForm } = this.state;
        const ProjectID = dataForm?.ProjectID || "";
        if (ProjectID) {
            const params = {
                ProjectID,
                AttendanceDate: dataForm.AttendanceDate ? dataForm.AttendanceDate : null
            };
            this.props.w29f2006Actions.getCboHandOver(params, (errors, data) => {
                if (errors) {
                    Config.popup.show('ERROR', errors);
                    return false;
                }
                if (data) {
                    this.setState({
                        dataHandOver: data
                    });
                }
            });
        }
    };

    loadCboShifts = () => {
        const { dataForm } = this.state;
        const params = {
            ProjectID: dataForm?.ProjectID || "",
            FormID: "W29F2006",
            DivisionID: Config.getDivisionID()
        };
        this.props.w29f2006Actions.getCboShifts(params, (errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (data) {
                this.setState({ dataShifts: data });
            }
        });
    };

    loadMaster = () => {
        const { AttendanceVoucherID } = this.getInfo();
        const { mode } = this.getInfo();
        const params = {
            AttendanceVoucherID: AttendanceVoucherID
        };
        this.props.w29f2006Actions.getGetMaster(params, (errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: {
                        AttendanceVoucherName: data.AttendanceVoucherName,
                        ProjectID: data?.ProjectID || "",
                        AttendanceDate: data.AttendanceDate ? moment(data.AttendanceDate).format("YYYY-MM-DD") : null,
                        HandOverID: data.HandOverID,
                        MasterNote: data.MasterNote
                    }
                }, () => {
                    this.dataOldForm = {...this.state.dataForm};
                    this.loadCboShifts();
                    this.loadCboColumns();
                    this.loadCboHandOver();
                    this.loadGridMaster();
                    if(mode !== "add") this.loadCboProjects(true, data?.ProjectID || "")
                });
            }
        });
    };

    loadGridMaster = () => {
        const { mode, AttendanceVoucherID } = this.getInfo();
        const { dataForm } = this.state;
        const params = {
            AttendanceVoucherID: AttendanceVoucherID ? AttendanceVoucherID : "",
            ProjectID: dataForm?.ProjectID || "",
            HandOverID: dataForm.HandOverID ? dataForm.HandOverID : "",
            AttendanceDate: dataForm.AttendanceDate ? dataForm.AttendanceDate : null
        };
        this.setState({ gridLoading: true });
        this.props.w29f2006Actions.getGridMaster(params, (errors, data) => {
            this.setState({ gridLoading: false });
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (data) {
                if (mode !== "add") {
                    this.dataOldAttendanceDetails = data.map(d => ({...d}));
                }
                let dataSelectedRowKey = [];
                data.forEach(item => {
                    dataSelectedRowKey.push(item.EmployeeID)
                });
                this.setState({
                    selectedRowKeys: dataSelectedRowKey,
                    dataAttendanceDetails: data
                }, () => {
                    this.loadStatusExitsEmployee(3);
                });
            }
        });
    };

    loadSelectedEmployees = () => {
        const { dataForm } = this.state;
        const params = {
            HostID: "",
            FormID: "W29F2006",
            AttendanceDate: dataForm.AttendanceDate ? dataForm.AttendanceDate : null
        };
        this.props.w29f2006Actions.getSelectedEmployees(params, (errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (data && data.length > 0) {
                let selectedEmployees = [...this.state.dataAttendanceDetails];
                let listEmployees = selectedEmployees.map((e) => {
                    return e.EmployeeID;
                });
                data.forEach((e) => {
                    if (listEmployees.indexOf(e.EmployeeID) < 0) {
                        selectedEmployees.unshift(e);
                    }
                });

                let dataSelectedRowKey = [];
                selectedEmployees.forEach(item => {
                    dataSelectedRowKey.push(item.EmployeeID)
                });

                this.setState({
                    selectedRowKeys: dataSelectedRowKey,
                    dataAttendanceDetails: selectedEmployees
                }, () => {
                    this.loadStatusExitsEmployee(4);
                });
            }
        });
    };

    loadStatusExitsEmployee = (mode) => {
        const { AttendanceVoucherID } = this.getInfo();
        const {dataForm} = this.state;
        const params = {
            ProjectID: dataForm?.ProjectID || "",
            HandOverID: dataForm?.HandOverID || "",
            AttendanceVoucherID: AttendanceVoucherID ? AttendanceVoucherID : "",
            Mode: mode,
            FormID: "W29F2006",
            Language: Config.language || "84"
        };
        this.props.w29f2006Actions.getStatusExitsEmployee(params, (errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if(data?.Status === 1) {
                Config.popup.show('INFO', data?.Message ?? "");
                return false;
            }

        });
    };

    loadHourDecimal = () => {
        this.props.w29f2006Actions.getHourDecimal((errors) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    getTotalHours = () => {
        this.props.w29f2006Actions.getTotalHours((err, data) => {
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
            this.setState({ isShowTotalHours: !!data?.TotalHours ?? false });
        });
    };

    getInfo = (flag) => {
        const { location } = this.props;
        if (location && location.state) {
            return {
                mode: location.state.mode ? location.state.mode : 'add',
                AttendanceVoucherID: location.state.AttendanceVoucherID,
                CurrentPage: location.state.CurrentPage,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W29F2005");
                return null;
            }
            return false;
        }
    };

    UNSAFE_componentWillMount() {
        if (!this.getInfo()) return;
        this.loadColumns();
        this.loadCoefficientColumn();
    }

    componentDidMount = async () => {
        // await this.loadPermission();
        // if (this.state.isPermission <= 0) return;
        if (!this.getInfo()) return;
        const { mode } = this.getInfo();
        this.loadHourDecimal();
        this.getTotalHours();
        if (mode === "add") this.loadCboProjects();
        if (mode && mode !== 'add') {
            await this.loadMaster();
            if (mode === "view") {
                this.setState({ disabled: true });
            }
        } else {
            this.loadCboShifts();
            // this.loadCboHandOver();
        }
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    handleChange = (key, e) => {
        if (!key) return false;
        const { dataForm } = this.state;
        switch (key) {
            case "AttendanceVoucherName":
                this.updateStateForm("AttendanceVoucherName", e.target.value);
                break;
            case "ProjectID":
                this.updateStateForm("ProjectID", e.value, () => {
                    const ProjectID = e.value || "";
                    const AttendanceVoucherName = (ProjectID ? ProjectID : "")
                        + (dataForm.HandOverID ? " - " + dataForm.HandOverID : "")
                        + (dataForm.AttendanceDate ? " - " + moment(dataForm.AttendanceDate).format("DD/MM/YYYY") : "");
                    this.updateStateForm("AttendanceVoucherName", AttendanceVoucherName);
                    if (e.data) {
                        this.loadCboHandOver();
                    } else {
                        this.setState({ dataHandOver: [] });
                    }
                    this.loadCboShifts();
                    this.loadCboColumns();
                    this.reloadGridMaster();
                });
                break;
            case "AttendanceDate":
                this.updateStateForm("AttendanceDate", e.value, () => {
                    const ProjectID = dataForm?.ProjectID || "";
                    const AttendanceVoucherName = (ProjectID ? ProjectID + " - " : "")
                        + (dataForm.HandOverID ? dataForm.HandOverID + " - " : "")
                        + (e.value ? moment(e.value).format("DD/MM/YYYY") : "");
                    this.updateStateForm("AttendanceVoucherName", AttendanceVoucherName);
                    this.loadCboHandOver();
                    this.reloadGridMaster();
                });
                break;
            case "HandOverID":
                this.updateStateForm("HandOverID", e.value, () => {
                    const ProjectID = dataForm?.ProjectID || "";
                    const AttendanceVoucherName = (ProjectID ? ProjectID : "")
                        + (e.value ? " - " + e.value + " - " : " - ")
                        + (dataForm.AttendanceDate ? moment(dataForm.AttendanceDate).format("DD/MM/YYYY") : "");
                    this.updateStateForm("AttendanceVoucherName", AttendanceVoucherName);
                    this.loadCboColumns();
                });
                break;
            case "MasterNote":
                this.updateStateForm("MasterNote", e.target.value);
                break;
            default:
                break;
        }
    };

    updateStateForm = (key, value, callbackAfterUpdate) => {
        const { dataForm } = this.state;
        const { mode } = this.getInfo();
        const handOverID = key === "ProjectID" && (mode && mode === 'add') ? { HandOverID: "" } : {};
        this.setState({
            dataForm: {
                ...dataForm,
                ...handOverID,
                [key]: value || "",
            }
        }, () => {
            if (callbackAfterUpdate) callbackAfterUpdate();
        });
    };

    reloadGridMaster = () => {
        const { dataForm } = this.state;
        // const AttendanceVoucherName = dataForm.AttendanceVoucherName;
        const ProjectID = dataForm?.ProjectID || "";
        // const HandOverID = dataForm.HandOverID;
        const AttendanceDate = dataForm.AttendanceDate;
        if (ProjectID && AttendanceDate) {
            this.loadGridMaster();
        }
    };

    openEmployeeSelect = () => {
        this.setState({ showEmployeeSelect: true });
        this.isRenderModalW09F6000 = true;
    };

    closeEmployeeSelect = () => {
        this.setState({ showEmployeeSelect: false });
    };

    onSelectedEmployee = () => {
        this.loadSelectedEmployees();
        this.setState({ showEmployeeSelect: false });
    };

    onSave = async () => {
        this.dataGrid.instance.saveEditData();
        const dataSource = this.dataGrid.instance.option('dataSource');
        const { dataForm } = this.state;

        //validate form...
        const validateRules = [
            {
                name: "AttendanceVoucherName",
                rules: "isRequired",
                value: dataForm.AttendanceVoucherName
            },
            {
                name: "ProjectID",
                rules: "isRequired",
                value:   dataForm?.ProjectID || ""
            },
            {
                name: "HandOverID",
                rules: "isRequired",
                value: dataForm.HandOverID
            },
            {
                name: "AttendanceDate",
                rules: "isRequired",
                value: dataForm.AttendanceDate
            }
        ];
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length !== 0) { //Neu co loi validate
            this.setStateErrorText(validateForm);
            return false;
        } else {
            if (dataSource.length < 1) {
                Config.popup.show('INFO', Config.lang("Khong_co_dong_du_lieu_duoc_cap_nhat"));
                return false;
            }
            if (!this._checkGridRequired(dataSource)) { //Chua du thong tin
                Config.popup.show('INFO', Config.lang("Bat_buoc_nhap_du_lieu"));
                return false;
            } else
                if (!this._checkGridEntered(dataSource)) { //Chua du thong tin
                    Config.popup.show('YES_NO', Config.lang("Con_dong_chua_duoc_nhap_lieu/Ban_co_muon_luu_khong?"), () => {
                        this._saveData(dataSource);
                    });
                    return false;
                } else {
                    Config.popup.show('YES_NO', Config.lang("Ban_co_chac_muon_luu_khong?"), () => {
                        this._saveData(dataSource);
                    });
                }
        }
    };

    _commitSave = (resultYesNo, AttendanceVoucherID) => {
        const { mode, AttendanceVoucherID: _AttendanceVoucherID } = this.getInfo();
        this.setState({ isSaving: true, loading: true }); //set status..
        const params = {
            Commit: resultYesNo,
            Mode: mode === "add" ? 1 : 2,
            AttendanceVoucherID: _AttendanceVoucherID ? _AttendanceVoucherID : "",
        };
        AttendanceVoucherID = AttendanceVoucherID ? AttendanceVoucherID : _AttendanceVoucherID;
        this.props.w29f2006Actions.commitAndSave(params, async (errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                this.setState({ isSaving: false, loading: false });
                return false;
            }
            if (data) {
                if (data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    this.setState({ isSaving: false, loading: false });
                    return false;
                } else if (data.Status !== 1 && resultYesNo === 1) {
                    //Yes And Show Save Success
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    await this.saveHistory(AttendanceVoucherID);
                    this.setState({ isSaving: false, loading: false, disabled: false });
                } else { //No
                    await this.saveHistory(AttendanceVoucherID);
                    this.setState({ isSaving: false, loading: false, disabled: false });
                }
            } else {
                this.setState({ isSaving: false });
            }
        });
    };

    saveHistory = async (AttendanceVoucherID) => {
        const { mode, AttendanceVoucherID: _AttendanceVoucherID } = this.getInfo();
        const {dataForm, dataHandOver} = this.state;
        const {getColumns} = this.props;
        let dataSource = this.dataGrid.instance.option('dataSource');
        // if (mode === "add") {
        //     const fields     = ["A01ID", "A02ID", "A03ID", "A04ID", "A05ID", "A06ID", "A07ID", "A08ID", "A09ID", "A10ID"];
        //     dataSource = dataSource.filter(d => {
        //         let correct = false;
        //         for (let field of fields) {
        //             if (!_.isEmpty(d[field])) {
        //                 correct = true;
        //                 break;
        //             }
        //         }
        //         return d.ExceedHour !== 0 || correct;
        //     });
        // }
        const TransID = AttendanceVoucherID ? AttendanceVoucherID : _AttendanceVoucherID;
        if (TransID) {
            //re-create data..
            const _data = {
                ...dataForm,
                AttendanceVoucherName: _.get(dataForm, "AttendanceVoucherName", ""),
                ProjectID: _.get(dataForm, "ProjectID", ""),
                HandOverID: _.get(dataForm, "HandOverID", ""),
                AttendanceDate: _.get(dataForm, "AttendanceDate", null),
            };
            const _dataOld = {
                ...this.dataOldForm,
                AttendanceVoucherName: _.get(this.dataOldForm, "AttendanceVoucherName", ""),
                ProjectID: _.get(this.dataOldForm, "Project.ProjectID", ""),
                HandOverID: _.get(this.dataOldForm, "HandOverID", ""),
                AttendanceDate: _.get(this.dataOldForm, "AttendanceDate", null),
            };

            //Init captions..
            const captions = {
                AttendanceVoucherName: "Phieu_cham_cong",
                ProjectID: "Du_an",
                HandOverID: "Ban_giao",
                AttendanceDate: "Ngay_cham_cong",
            };
            let detailCaptions = {
                EmployeeID: "Ma_nhan_vien",
                EmployeeName: "Ten_nhan_vien",
                ShiftID: "Ca",
                AttendanceHour: "Gio_cong",
            };
            getColumns && getColumns.forEach(col => {
                if (col.IsUsed) {
                    detailCaptions[col.TypeID] = null;
                }
            });

            const options = {
                data: _data,
                captions: captions,
                dataCompare: _dataOld,
                // action: mode === "add" ? 0 : 1,
                ModuleID: "D29",
                TransID: TransID,
                TransactionID: "W29F2006",
                TransactionName: "Chấm công theo dự án",
                itemRender: (e, type) => {
                    const {key, data} = e;
                    if (type === "Content") {
                        switch (key) {
                            case "ProjectID":
                                const ProjectID = _.get(data, "Project.ProjectID", "");
                                const ProjectName = _.get(data, "Project.ProjectName", "");
                                return ProjectID + " - " + ProjectName;
                            case "HandOverID":
                                const HandOverID = _.get(data, "HandOverID", "");
                                const handOver = dataHandOver && dataHandOver.find(d => d.HandOverID === HandOverID);
                                const HandOverName = handOver && handOver.HandOverName ? handOver.HandOverName : "";
                                return HandOverID + " - " + HandOverName;
                            default: break;
                        }
                    }
                }
            };
            const history = new History({...options, action: mode === "add" ? 0 : 1}); //Init history
            history.createDetailHistory("Nhan_vien", dataSource, this.dataOldAttendanceDetails, detailCaptions,"EmployeeID",  null, options ); //Create detail..
            // const allData = await history.get();
            // console.log("test", allData);
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }

    };

    _saveData = (data) => {
        const dataSource = data ? data : this.dataGrid.instance.option('dataSource');
        const { dataForm } = this.state;
        const { mode, AttendanceVoucherID } = this.getInfo();
        this.setState({ isSaving: true, loading: true }); //set status..
        let disabledField = false;
        const params = {
            // HostID: "",
            // FormID: "W29F2006",
            AttendanceVoucherName: dataForm.AttendanceVoucherName ? dataForm.AttendanceVoucherName : "",
            ProjectID: dataForm?.ProjectID || "",
            HandOverID: dataForm.HandOverID ? dataForm.HandOverID : "",
            AttendanceDate: dataForm.AttendanceDate ? dataForm.AttendanceDate : null,
            MasterNote: dataForm.MasterNote ? dataForm.MasterNote : "",
            Language: Config.language || "84",
            data: JSON.stringify(dataSource)
        };
        if (mode === 'edit') {
            params["AttendanceVoucherID"] = AttendanceVoucherID || "";
        } else if (mode === 'add') {
            disabledField = true;
        }
        this.props.w29f2006Actions[mode === "add" ? "saveAddAttendance" : "saveEditAttendance"](params, async (errors, data) => {
            if (errors) {
                errors.customMessage = {
                    "F2006E008": "AttendanceVoucherID " + Config.lang("Bat_buoc")
                };
                Config.popup.show('ERROR', errors);
                this.setState({ isSaving: false, loading: false });
                return false;
            }
            if (data) {
                const _AttendanceVoucherID = data && data.AttendanceVoucherID ? data.AttendanceVoucherID : "";
                if (data.Status === 1) {
                    if (data.MsgAsk === 0) {
                        let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        this.setState({ isSaving: false, loading: false });
                        return false;
                    } else { // MsgAsk === 1  commit to save
                        Config.popup.show('YES_NO', data.Message, () => {
                            this._commitSave(1, _AttendanceVoucherID); // Yes
                        }, () => {
                            this._commitSave(0, _AttendanceVoucherID); // No
                        });
                    }
                } else {
                    //Show Save Success
                    await this.saveHistory(_AttendanceVoucherID);
                    this.setState({ isSaving: false, loading: false, disabled: disabledField });
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                }
            } else {
                this.setState({ isSaving: false });
            }
        });
    };

    _checkGridRequired = (data) => {
        const dataSource = data ? data : this.dataGrid.instance.option('dataSource');
        const { getColumns } = this.props;
        let isEntered = true;
        if (dataSource.length < 1) return false;
        dataSource.every((e, idx) => {
            getColumns.every((col) => {
                if (col.IsUsed === 1 && col.CheckMode === 1 && e.IsEdited === 0 && !e[col.TypeID]) {
                    this.dataGrid.instance.editCell(idx, col.TypeID);
                    isEntered = false;
                    return false;
                }
                return true;
            });
            return isEntered;
        });
        return isEntered;
    };

    _checkGridEntered = (data) => {
        const dataSource = data ? data : this.dataGrid.instance.option('dataSource');
        const { getColumns } = this.props;
        let isEntered = true;
        if (dataSource.length < 1) return false;
        dataSource.every((e) => {
            isEntered = !!e.AttendanceHour;
            !isEntered && getColumns.every((col) => {
                if (col.IsUsed === 1) {
                    isEntered = !!e[col.TypeID];
                }
                return !isEntered;
            });
            return isEntered;
        });
        return isEntered;
    };

    onEditingStart = (e) => {
        const { data } = e;
        if (data.IsEdited) {
            e.cancel = true;
        }
    };

    onBack = () => {
        const { CurrentPage } = this.getInfo();
        browserHistory.push({
            pathname: Config.getRootPath() + "W29F2005",
            state: { CurrentPage },
        });
    };

    onDelete = (e) => {
        e.component.deleteRow(e.rowIndex);
    };

    onLongPressDelete = (e) => {
        if (!e || !e.currentSelectedRowKeys[0]) return;
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            const { TransID } = e.currentSelectedRowKeys[0];
            const { dataAttendanceDetails } = this.state;
            const rowSelected = dataAttendanceDetails.findIndex(o => o.TransID === TransID);
            e.component.deleteRow(rowSelected);
        });
    };

    cellRenderAction = (e) => {
        return (
            <GridActionBar>
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"delete"}
                    onClick={() => this.onDelete(e)}
                />
            </GridActionBar>
        );
    };

    toggleDefault = () => {
        this.setState({
            defaultVisible: !this.state.defaultVisible
        });
    };

    resultToggleOutside = () => {
        this.setState({
            defaultVisible: !this.state.defaultVisible
        });
        return this.state.defaultVisible;
    };

    headerCellCustomIcon = () => {
        return (
            <>
                <HelpIcon
                    id="tooltip"
                    fontSize="small"
                    onClick={() => this.toggleDefault()}
                />
                <Tooltip
                    className={'guid-popup'}
                    target="#tooltip"
                    position="top"
                    visible={this.state.defaultVisible}
                    closeOnOutsideClick={() => this.resultToggleOutside()}
                >
                    <div>{Config.lang("Huong_dan_xoa_nhan_vien")}</div>
                </Tooltip>
            </>
        )
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { mode } = this.getInfo();
        const { getHourDecimal, getColumns, getCboColumns, getCoefficientColumn } = this.props;
        const { disabled, loading, error, showEmployeeSelect, gridLoading, isSaving,
            dataHandOver, dataForm, dataShifts, dataCboProjects, projectLoading, selectedRowKeys, isShowTotalHours
        } = this.state;
        let { dataAttendanceDetails } = this.state;
        let isLongClick = 1;
        let columnsCaption = _.map(getColumns, item => ({
            id: item.TypeID,
            caption: item.Description,
            isUsed: item.IsUsed,
            checkMode: item.CheckMode,
            isValueCb: true,
        }))
        if (_.size(columnsCaption) === _.size(getCoefficientColumn)) {
            columnsCaption = _.chain(columnsCaption).reduce((acc, currItem, currIndex) => {
                const currColumnCaption = _.get(getCoefficientColumn, [currIndex], "");
                acc = [...acc, currItem,
                    {
                        ...(_.toNumber(currColumnCaption?.Code.match(/\d+/g)[0]) < 11 ? {
                            id: currColumnCaption?.Code || "",
                            caption: currColumnCaption?.ShortName || "",
                            isUsed: currColumnCaption?.IsUsed,
                            checkMode: 0,
                            isValueCb: false
                        } : {})
                    }
                ]
                return acc;
            }, []).filter(["isUsed", 1]).value();
        }
        if (isShowTotalHours && dataAttendanceDetails?.length > 0) {
            dataAttendanceDetails = dataAttendanceDetails.map(item => {
                item.TotalHours = Number(item?.AttendanceHour ?? 0);
                if (getCoefficientColumn?.length > 0) {
                    getCoefficientColumn.forEach((column, index) => {
                        if (index < 3 && column?.IsUsed) {
                            item.TotalHours += Number(item?.[column?.Code] ?? 0);
                        }
                    });
                }
                item.TotalHours = item.TotalHours || ''; 
                return item; 
             });
        }

        return (
            <React.Fragment>
                {/*<FormGroup style={{margin: -15}}>*/}
                {/*    <iframe src={"https://apricot.diginet.com.vn/erp-staging"}*/}
                {/*            title={this.state.downloadName}*/}
                {/*            onLoad={this.loadedOnPreview}*/}
                {/*            height={880}*/}
                {/*        // className={classes.view}*/}
                {/*            width={'100%'} />*/}
                {/*</FormGroup>*/}
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("Cham_cong_chi_tiet")}
                        onBack={this.onBack}
                    >
                        <ButtonCustom name={Config.lang('Chon_nhan_vien')}
                            color={"primary"}
                            variant={"custom"}
                            icon={<InlineSVG className={'btn-icon-svg'} src={require('../../../../assets/images/general/people.svg')} />}
                            style={{ textTransform: 'uppercase' }}
                            size={"large"}
                            className={"mgr5"}
                            disabled={isSaving || disabled}
                            onClick={this.openEmployeeSelect} />
                        <ButtonGeneral name={Config.lang('Luu')}
                            typeButton={'save'}
                            disabled={disabled}
                            loading={isSaving}
                            style={{ textTransform: 'uppercase' }}
                            size={"large"}
                            onClick={this.onSave} />
                    </ActionToolbar>
                </FormGroup>
                {/*<Modal open={showEmployeeSelect} maxWidth={"lg"} fullWidth={true}>*/}
                {(showEmployeeSelect || this.isRenderModalW09F6000) && <W09F6000 open={showEmployeeSelect} FormID={"W29F2006"} dataDefaults={{dataSelected:dataAttendanceDetails,selectedRowKeys,isLoaded: selectedRowKeys && selectedRowKeys.length > 0}}  onChosen={this.onSelectedEmployee} onClose={this.closeEmployeeSelect} />}
                {/*</Modal>*/}
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={9} lg={9}>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <TextInput
                                            error={error && error['AttendanceVoucherName']}
                                            label={Config.lang('Phieu_cham_cong')}
                                            disabled={true}
                                            value={dataForm.AttendanceVoucherName}
                                            required
                                            readOnly
                                            onChange={(e) => this.handleChange('AttendanceVoucherName', e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Dropdown
                                            disabled={loading || disabled || mode !== "add" }
                                            allowSearch
                                            dataSource={dataCboProjects.rows}
                                            total={dataCboProjects.total}
                                            skip={this.filterCboProjects.skip}
                                            limit={this.filterCboProjects.limit}
                                            displayExpr={"{ProjectID} - {ProjectName}"}
                                            valueExpr={"ProjectID"}
                                            value={dataForm.ProjectID}
                                            loading={projectLoading}
                                            clearAble
                                            label={Config.lang('Du_an')}
                                            placeholder={Config.lang('Chon')}
                                            onChange={(e) => this.handleChange("ProjectID", e)}
                                            onInput={(e) => {
                                                this.filterCboProjects.strSearch = e.target.value;
                                                this.filterCboProjects.skip = 0;
                                                this.loadCboProjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProjects.skip = e.skip;
                                                this.filterCboProjects.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <DatePicker
                                            clearAble
                                            label={Config.lang("Ngay_cham_cong")}
                                            error={error && error['AttendanceDate']}
                                            required
                                            value={dataForm.AttendanceDate}
                                            disabled={loading || disabled || mode === "edit"}
                                            displayFormat={"DD/MM/YYYY"}
                                            onChange={(e) => this.handleChange('AttendanceDate', e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Dropdown
                                            error={error && error['HandOverID']}
                                            dataSource={dataHandOver}
                                            displayExpr={'{HandOverID} - {HandOverName}'}
                                            valueExpr={'HandOverID'}
                                            required
                                            placeholder={Config.lang('Chon')}
                                            disabled={loading || disabled || mode === "edit"}
                                            value={dataForm.HandOverID}
                                            shrink={true}
                                            label={Config.lang('Ban_giao')}
                                            onChange={(e) => this.handleChange("HandOverID", e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            label={Config.lang('Ghi_chu')}
                                            disabled={loading || disabled}
                                            value={dataForm.MasterNote}
                                            onChange={(e) => this.handleChange('MasterNote', e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                reference={ref => this.dataGrid = ref}
                                dataSource={dataAttendanceDetails}
                                keyExpr={"EmployeeID"}
                                hoverStateEnabled={true}
                                showBorders={false}
                                columnAutoWidth={true}
                                columnResizingMode={'widget'}
                                onCellClick={() => Config.isMobile ? isLongClick = 0 : ""}
                                onSelectionChanged={e => Config.isMobile && isLongClick === 1 ? this.onLongPressDelete(e) : ""}
                                filterRow={{
                                    visible: true,
                                    showOperationChooser: false,
                                }}
                                typeShort={window.innerWidth < 768}
                                loading={gridLoading}
                                disabled={isSaving}
                                editing={{
                                    mode: 'cell',
                                    refreshMode: 'reshape',
                                    allowUpdating: !disabled,
                                    texts: {
                                        confirmDeleteMessage: ""
                                    },
                                    startEditAction: "click"
                                }}
                                onEditingStart={this.onEditingStart}
                                onRowUpdated={(e) => {
                                    e.data.IsUpdated = 1;
                                    if (isShowTotalHours) {
                                        e.data.TotalHours = Number(e.data?.AttendanceHour ?? 0);
                                        if (getCoefficientColumn?.length > 0) {
                                            getCoefficientColumn.forEach((column, index) => {
                                                if (index < 3 && column?.IsUsed) {
                                                    e.data.TotalHours += Number(e.data?.[column?.Code] ?? 0);
                                                }
                                            });
                                        }
                                        e.data.TotalHours =  e.data.TotalHours || ''; 
                                    }
                                }}
                            >
                                <Column
                                    visible={Config.isMobile}
                                    headerCellComponent={this.headerCellCustomIcon}
                                    cssClass={'iconGuids'}
                                    width={0}
                                />
                                <Column
                                    fixed={true}
                                    caption={Config.lang("Ma_nhan_vien")}
                                    dataField={'EmployeeID'}
                                    allowEditing={false}
                                    minWidth={150}
                                />
                                <Column
                                    fixed={!Config.isMobile}
                                    caption={Config.lang("Ten_nhan_vien")}
                                    dataField={'EmployeeName'}
                                    allowEditing={false}
                                    minWidth={180}
                                />
                                {isShowTotalHours && <Column
                                    caption={Config.lang("Tong_gio")}
                                    dataField={'TotalHours'}
                                    dataType={'number'}
                                    format={{
                                        type: 'decimal',
                                        min: 0
                                    }}
                                    allowEditing={false}
                                    minWidth={140}
                                />}
                                <Column
                                    caption={Config.lang("Gio_cong")}
                                    minWidth={100}
                                    dataField={'AttendanceHour'}
                                    dataType={"number"}
                                    format={{
                                        type: 'decimal',
                                        min: 0
                                    }}
                                    editorOptions={{
                                        min: 0,
                                        showClearButton: true,
                                        format: {
                                            type: 'fixedPoint',
                                            precision: getHourDecimal && getHourDecimal.HourDecimal ? getHourDecimal.HourDecimal : 4,
                                        },
                                    }}
                                />
                                {columnsCaption && columnsCaption.map((e, i) => {
                                    let columnProps = {
                                        dataType: "number",
                                        format: {
                                            type: 'decimal',
                                            min: 0,
                                        },
                                        editorOptions:{
                                            min: 0,
                                            showClearButton: true,
                                            format: {
                                                type: 'fixedPoint',
                                                precision: 4
                                            },
                                    }};
                                    if (e.isValueCb) {
                                        const dataLookup = getCboColumns.filter((item) => {
                                            return item.TypeID === e.id;
                                        });
                                        columnProps = {
                                            lookup: {
                                                allowClearing: true,
                                                dataSource: dataLookup,
                                                valueExpr: "AcodeID",
                                                displayExpr: "Description",
                                            }
                                        }
                                    }

                                    return (
                                        <Column
                                            key={i}
                                            caption={e.caption}
                                            alignment={"left"}
                                            headerCellRender={(header) => {
                                                return (<>
                                                    {header.column.caption}
                                                    {e.CheckMode === 1 && <span style={{color: 'red'}}>&nbsp;*</span>}
                                                </>);
                                            }}
                                            minWidth={140}
                                            dataField={e.id}
                                            {...columnProps}
                                            // cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                                        >
                                            {e.checkMode === 1 && <RequiredRule/>}
                                        </Column>
                                    );
                                })
                                }
                                <Column
                                    caption={Config.lang("Phep")}
                                    minWidth={120}
                                    dataField={'LeaveTypeName'}
                                    allowEditing={false}
                                />
                                <Column
                                    caption={Config.lang("Ca")}
                                    dataField={'ShiftID'}
                                    lookup={{
                                        dataSource: dataShifts,
                                        valueExpr: "ShiftID",
                                        displayExpr: "ShiftName",
                                    }}
                                    minWidth={120}>
                                </Column>
                                <Column
                                    caption={Config.lang("Gio_vao")}
                                    dataField={'TimeOn'}
                                    allowEditing={false}
                                    minWidth={90}
                                />
                                <Column
                                    caption={Config.lang("Gio_ra")}
                                    dataField={'TimeOff'}
                                    allowEditing={false}
                                    minWidth={90}
                                />
                                <Column
                                    caption={Config.lang("So_gio_vuot")}
                                    dataField={'ExceedHour'}
                                    dataType={"number"}
                                    minWidth={100}
                                />
                                <Column
                                    caption={Config.lang("Ghi_chu")}
                                    minWidth={200}
                                    dataField={'DetailNote'}
                                />
                                <Column
                                    alignment={"center"}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    allowEditing={false}
                                    // visible={!disabled}
                                    visible={false} // BA yêu cầu luôn ẩn button Delete (Ẩn chứ ko xoá)
                                    cellRender={this.cellRenderAction}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect((state) => ({
    getColumns: state.W29F2006.getColumns,
    getCoefficientColumn: state.W29F2006.getCoefficientColumn,
    getCboColumns: state.W29F2006.getCboColumns,
    getHourDecimal: state.W29F2006.getHourDecimal,
    getMaster: state.W29F2006.getMaster,
    getGridMaster: state.W29F2006.getGridMaster,
}), (dispatch) => ({
    w29f2006Actions: bindActionCreators(W29F2006Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})))(W29F2006);
