import React                         from "react";
import {connect}                     from "react-redux";
import PropTypes                     from "prop-types";
import * as W25F2303Actions          from "../../../../redux/W2X/W25F2303/W25F2303_actions";
import * as generalActions           from "../../../../redux/general/general_actions";
import {bindActionCreators, compose} from "redux";
import Config                        from "../../../../config";
import {TextField as TextFieldM}     from '@material-ui/core';
import { Editor }                    from '@tinymce/tinymce-react';
import Attachments                   from "../../../common/attachments/attachments";
import MForm                         from "../../../common/form-material/form";
import UserImage                     from "../../../common/user/user-image";
import {TextBox}                     from "devextreme-react";
import moment                        from "moment";
import InputMask                     from "react-input-mask";
import { Column, CustomRule, RequiredRule } from "devextreme-react/data-grid";
import ArrowDropDownIcon            from '@material-ui/icons/ArrowDropDown';
import {IconButton, withStyles}     from "@material-ui/core";
import PopupSelect2                  from "../../../popup/popup-select2";
import PopupAttachment              from '../../../common/popup-attachment/PopupAttachment'
import CDN                          from "../../../CDN";
import HeadClick                    from "../../../grid-container/head-click";
import GridCandidate                from "./GridCandidate";
import {
    Toggle,
    Button,
    TextInput,
    Dropdown,
    HelperText,
    DateRangePicker,
    Radio,
    Row,
    Col,
    Typography,
    Label,
    NumberInput,
    DatePicker,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "diginet-core-ui/components";
import { Loading } from "../../../common/loading/loading";

const styles = () => ({
    mailEditor: {
        width: "100%",
    },
    paddingTitle: {
        padding: "15px 0 0 0",
    },
    labelStyle: {
        fontSize: 12,
        fontWeight: 700,
        color: "#555868"
    },
    paddingRadio: {
        '& .MuiIconButton-root': {
            padding: '10px 10px 10px 0'
        }
    },
    textBox: {
        width: 'calc(100% - 32px)'
    },
    buttonDatebox: {
        '& .dx-button-content > i': {
            fontSize: 20
        }
    },
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },

});
class W25F2303 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            RecInfoID: "",
            CandidateID: "",
            InterviewerList: [],
            InterviewType: "ONSITE",
            CanConfirmStatusID: "",
            InterviewFileID: "",
            SendMailMode: 1,
            InterviewTitle: "",
            CandidateEmail: "",
            InterviewScheduleID: "",
            InterviewScheduleName: "",
            NumSendMail: 0,
            DateFrom: null,
            DateTo: null,
            InterviewerMail: [],
            InterviewDate: "",
            InterviewTime: null,
            Minutes: "",
            InterviewPlace: "",
            TemplateMailID: "",
            TitleMail: "",
            uploading: false,
            dataOldAttachments: [],
            dataItemCandidate: {},
            Employee: [],
            loadingCboCandidate: false,
            loadingListInterview: false,
            loadingAttachments: false,
            loadingCboMailTemplate: false,
            isShowGrid: false,
            StageID: "",
            StageName: "",
            dataCboCandidates: [],
            dataGridCandidates: [],
            showPopupListMember: false,
            showPopupAttachment: false,
            dataAttachmentPopup: [],
            selectedRowKeys: [],
            iPermission: 0,
            loadingListCandidate: false,
            loadingSave: false
        };
        this.interviewType = [
            {interviewTypeID : "ONSITE", interviewTypeNameVI: "Phỏng vấn tại văn phòng", interviewTypeNameEN: "ONSITE"},
            {interviewTypeID : "VIDEO", interviewTypeNameVI: "Phỏng vấn online", interviewTypeNameEN: "VIDEO"},
            {interviewTypeID : "PHONE", interviewTypeNameVI: "Phỏng vấn qua điện thoại", interviewTypeNameEN: "PHONE"},
        ];
        this.cboSendMailMode = [
            {sendMailModeID: 0, sendMailModeName: Config.lang("Khong_gui_email")},
            {sendMailModeID: 1, sendMailModeName: Config.lang("Chi_ung_vien")},
            {sendMailModeID: 2, sendMailModeName: Config.lang("Chi_nguoi_phong_van")},
            {sendMailModeID: 3, sendMailModeName: Config.lang("Ung_vien_va_nguoi_phong_van")},
        ];
        this.attachments = [];
        this.deletedFile = [];
        this.setData = false;
        this.timer = null;
        this.refDataGrid = null;
        this.selectedRowIndx = 0;
        this.attachmentsPopup = [];
        this.deletedFilePopup = [];
        this.selectedRange = {};
        this.checkEditData = false;
        this.popupInterviewerList = null;
        this.refEditorNoteInteranal = null;
        this.refEditorContent = null;
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W25F2010", (isPer) => {
            this.setState({iPermission: isPer});
        }, true);
    };

    loadAttachments = () => {
        const {data} = this.props;
        const param = {
            KeyID: data?.InterviewFileID || "",
            TableName: "D25T2303"
        };
        this.setState({loadingAttachments: true});
        this.props.generalActions.getAttachmentsByTransID(param, (err, data) => {
            this.setState({loadingAttachments: false});
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    loadCboCandidate = (mode) => {
        const {FormID, data, RecInfoID, CandidateID} = this.props;
        const {isShowGrid} = this.state;
        const params = (isShowGrid && mode !== 0) ? {
            FormID: FormID || "",
            RecInfoID: data?.RecInfoID || RecInfoID || "",
            CandidateID: data?.CandidateID || CandidateID || "",
            InterviewScheduleID: data?.InterviewScheduleID || "",
            Mode: mode,
        } : {
            FormID: FormID || "",
            RecInfoID: data?.RecInfoID || RecInfoID || "",
            CandidateID: data?.CandidateID || CandidateID || "",
            Mode: mode,
        };
        this.setLoading('CboCandidate', true);
        this.props.w25F2303Actions.getCboCandidate(params,(errors, data) => {
            this.setLoading('CboCandidate', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if(data) {
                this.setState({dataCboCandidates: data})
            }
        });
    };

    loadGridCandidate = () => {
        const {FormID, data, RecInfoID} = this.props;
        const params = {
            FormID: FormID || "",
            RecInfoID: data?.RecInfoID || RecInfoID || "",
        };
        this.setLoading('ListCandidate', true);
        this.props.w25F2303Actions.getListCandidate(params,(errors, data) => {
            this.setLoading('ListCandidate', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if(data) {
                data.forEach(item => {
                    if(!item.InterviewType){
                        item.InterviewType = "ONSITE";
                    }
                    if(!item.InterviewDateText) {
                        item.InterviewDateText = "";
                    }
                    if(!item.SendMailMode) {
                        item.SendMailMode = 1;
                    }
                    if(!item.attachments) {
                        item.attachments = [];
                    }
                    if(!item.InterviewerList) {
                        item.InterviewerList = "";
                    }
                    if(!item.NumSendEmail) {
                        item.NumSendEmail = 0;
                    }
                    if(!item.IsUsed){
                        item.IsUsed = 0;
                    }
                });
                let selectedRow = [];
                data.forEach(item => {
                    if(item.IsUsed) {
                        selectedRow.push(item)
                    }
                });
                this.setState({dataGridCandidates: data,selectedRowKeys: selectedRow })
            }
        });
    };

    loadCboMailTemplate = () => {
        this.setLoading('CboMailTemplate', true);
        this.props.w25F2303Actions.getCboMailTemplate((errors) => {
            this.setLoading('CboMailTemplate', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    loadDataForm =  () => {
        const {data, isMultipleSchedule} = this.props;
        const params = {
            FormID: "W25F2303",
            RecInfoID: data?.RecInfoID || "",
            CandidateID: data?.CandidateID || "",
            InterviewFileID: data?.InterviewFileID || "",
            InterviewScheduleID: data?.InterviewScheduleID || "",
            SerialMode: isMultipleSchedule ? 1 :0,
        };
        this.setLoading('DataForm', true);
        this.props.w25F2303Actions.getDataForm(params,(errors,dataForm) => {
            this.setLoading('DataForm', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if(dataForm) {
                const typeInterview =  this.interviewType.filter(val => val.interviewTypeNameEN === dataForm.InterviewType);
                if(this.refEditorNoteInteranal) {
                    this.refEditorNoteInteranal.setContent(dataForm?.NoteInteranal || "");
                }
                if(this.refEditorContent) {
                    this.refEditorContent.setContent(dataForm?.Content || "");
                }
                if(isMultipleSchedule) {
                    dataForm.detail.forEach(item => {
                        if(!item.InterviewType){
                            item.InterviewType = "ONSITE";
                        }
                        if(!item.InterviewDateText) {
                            if(moment.utc(item?.InterviewDate).format("HH:mm") === "00:00") {
                                item.InterviewDateText = "";
                            }
                            else item.InterviewDateText = moment.utc(item?.InterviewDate).format("HH:mm")
                        }
                        if(!item.SendMailMode) {
                            item.SendMailMode = 1;
                        }
                        if(!item.attachments) {
                            item.attachments = [];
                        }
                        if(!item.InterviewerList) {
                            item.InterviewerList = "";
                        }
                        if(!item.NumSendEmail) {
                            item.NumSendEmail = 0;
                        }
                    });
                    let selectedRow = [];
                    dataForm.detail.forEach(item => {
                        if(item.IsUsed) {
                            selectedRow.push(item)
                        }
                    });
                    this.setState({
                        InterviewFileID: data?.InterviewFileID || "",
                        InterviewScheduleName: data?.InterviewScheduleName || "",
                        DateFrom: moment(data?.DateFrom).isValid()
                            ? moment.utc(data?.DateFrom).format("YYYY-MM-DD")
                            : null,
                        DateTo: moment(data?.DateTo).isValid() ? moment.utc(data?.DateTo).format("YYYY-MM-DD") : null,
                        dataGridCandidates: dataForm.detail,
                        selectedRowKeys: selectedRow,
                    });
                }
                else{
                    this.setState({
                        CandidateID: data?.CandidateID || "",
                        dataItemCandidate: {
                            CandidateID: data?.CandidateID || "",
                            CandidateEmail: data?.CandidateEmail || dataForm?.CandidateEmail || "",
                            RecInfoID: data.RecInfoID || "",
                            CandidateName: data.CandidateName || dataForm?.CandidateName || "",
                            CompanyAddress: data.CompanyAddress || dataForm?.CompanyAddress || "",
                            CompanyName: data.CompanyName || dataForm?.CompanyName || "",
                            RecpositionName: data.RecpositionName || dataForm?.RecpositionName || "",
                            DepartmentName: data.DepartmentName || dataForm.DepartmentName || '',
                            RecInfoTitle: data.RecInfoTitle || dataForm.RecInfoTitle || '',
                            UserName: data.UserName || dataForm.UserName || '',
                            UserFirstname: data.UserFirstname || dataForm.UserFirstname || '',
                            UserPhone: data.UserPhone || dataForm.UserPhone || '',
                            UserEmail: data.UserEmail || dataForm.UserEmail || '',
                            InterviewDay: data.InterviewDay || dataForm.InterviewDay || '',
                            StageID: data?.StageID || dataForm?.StageID || "",
                            StageName: data?.StageName || dataForm?.StageName || "",
                        },
                        StageID: dataForm?.StageID || "",
                        StageName: dataForm?.StageName || "",
                        CanConfirmStatusID: data?.CanConfirmStatusID || "",
                        BirthDate: data?.BirthDate || null,
                        InterviewFileID: data?.InterviewFileID || "",
                        InterviewTitle: dataForm?.InterviewTitle || "",
                        InterviewPlace: dataForm?.InterviewPlace || "",
                        CandidateName: dataForm?.CandidateName || "",
                        InterviewDate: moment(dataForm?.InterviewDate).isValid() ? moment.utc(dataForm?.InterviewDate).format("YYYY-MM-DD") : null,
                        InterviewTime:  dataForm?.InterviewDate ? moment.utc(dataForm.InterviewDate).format("HH:mm") : null,
                        InterviewType: typeInterview && typeInterview.length > 0 ? typeInterview[0].interviewTypeID : "ONSITE",
                        InterviewerList: dataForm.InterviewerList ? dataForm.InterviewerList.replace(/,/g, ';').split(';') : [],
                        Minutes: dataForm?.Minutes || "",
                        Mobile: dataForm?.Mobile || "",
                        SendMailMode:  dataForm?.SendMailMode ||  dataForm?.SendMailMode === 0 ? dataForm.SendMailMode : 1,
                    });
                }
            }
        });
    };

    loadListInterview = () => {
        this.setLoading('ListInterview', true);
        this.props.w25F2303Actions.getListInterview((errors) => {
            this.setLoading('ListInterview', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    resetForm = () => {
        this.setState({
            error: {},
            RecInfoID: "",
            CandidateID: "",
            InterviewerList: [],
            InterviewType: "ONSITE",
            CanConfirmStatusID: "",
            InterviewFileID: "",
            SendMailMode: 1,
            InterviewTitle: "",
            CandidateEmail: "",
            InterviewerMail: [],
            InterviewDate: "",
            InterviewTime: null,
            Minutes: "",
            InterviewPlace: "",
            InterviewScheduleID: "" ,
            InterviewScheduleName: "" ,
            NumSendMail: 0,
            DateFrom: "",
            DateTo: "",
            TemplateMailID: "",
            TitleMail: "",
            uploading: false,
            dataOldAttachments: [],
            dataItemCandidate: {},
            Employee: [],
            isShowGrid: false,
            StageID: "",
            showPopupListMember: false,
            showPopupAttachment: false,
            dataAttachmentPopup: [],
            selectedRowKeys: [],
            loadingListCandidate: false,
        });
        this.attachments = [];
        this.deletedFile = [];
        this.setData = false;
        this.refDataGrid = null;
        this.selectedRowIndx = 0;
        this.deletedFilePopup = [];
        this.selectedRange = {};
        this.refDataGrid = null;
        this.attachmentsPopup = [];
        this.checkEditData = false;
        if(this.attRef) {
            this.attRef.onReset();
        }
        if(this.refEditorNoteInteranal) {
            this.refEditorNoteInteranal.setContent('');
        }

        if(this.refEditorContent) {
            this.refEditorContent.setContent('')
        }
    };

    onClose = (isSaved = false) => {
        const {onClose, FormID} = this.props;
        if(FormID === "W25F2018") this.resetForm();
        if (onClose) onClose(isSaved);
    };

    getInterViewMail = (data, array) => {
        const { getListInterviews } = this.props;
        data.forEach((userID) => {
            const interviewer =  getListInterviews.find((val) => val.UserID === userID );
            if (interviewer && interviewer.InterviewerMail !== "") {
                array.push(interviewer.InterviewerMail)
            }
        });
    };
    onAdd = (isContinue = false, typeSave = false, isSendMail = false) => {
        this.setLoading('Save', true);
        const { mode, changedStage, data } = this.props;
        const {
            InterviewType,
            InterviewerList,
            CanConfirmStatusID,
            InterviewTitle,
            InterviewDate,
            Minutes,
            InterviewPlace,
            SendMailMode,
            InterviewTime,
            dataItemCandidate,
            InterviewFileID,
            InterviewScheduleName,
            DateFrom,
            DateTo,
            TitleMail,
            TemplateMailID,
        } = this.state;
        let dataSource = [];
        let dataIsUsed = [];
        let InterviewerMail = [];
        let modeSave = !typeSave ? "save" : isSendMail ? "sendMail" : "saveMultiple";
        if(this.refDataGrid && typeSave) {
            this.refDataGrid.instance.saveEditData();
            const editDataUnSave = this.refDataGrid.instance.hasEditData();
            if(editDataUnSave) {
                this.setLoading('Save', false);
                return false;
            }
        dataSource = this.refDataGrid?.instance.getDataSource().items();
        const validateRules = [
            {
                name: "InterviewScheduleName",
                rules: "isRequired",
                value: InterviewScheduleName,
            },
            {
                name: "DateFrom",
                rules: "isRequired",
                value: DateFrom,
            },
            {
                name: "DateTo",
                rules: "isRequired",
                value: DateTo,
            },
        ];
        dataIsUsed = dataSource.filter(item => item && item.IsUsed === 1);
        isSendMail && (dataIsUsed = dataIsUsed.filter(item => item && item.SendMailMode !== 0));
        if(dataIsUsed.length && !TemplateMailID && isSendMail){
            validateRules.push({
                name: "TemplateMailID",
                rules: "isRequired",
                value: TemplateMailID,
            });
        }

        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm, true);
            this.setLoading('Save', false);
            return false;
        }
        if(dataIsUsed && dataIsUsed.length > 0) {
            const InterviewType = dataIsUsed.find(item => item.InterviewType === "");
            const InterviewTitle = dataIsUsed.find(item => item.InterviewTitle === "");
            const InterviewDate = dataIsUsed.find(item => item.InterviewDate === "");
            const Minutes = dataIsUsed.find(item => item.Minutes === "");
            const InterviewPlace = dataIsUsed.find(item => item.InterviewPlace === "");
            const InterviewerList = dataIsUsed.find(item => item.InterviewerList === "");

            if(InterviewType) {
                this.refDataGrid.instance.navigateToRow(InterviewType.CandidateID);
                setTimeout(() => {
                    const rowIndx = this.refDataGrid.instance.getRowIndexByKey(InterviewType.CandidateID);
                    this.refDataGrid.instance.editCell(rowIndx, "InterviewType");
                }, 200);
                this.setLoading('Save', false);
                return false;
            }
            if(InterviewTitle) {
                this.refDataGrid.instance.navigateToRow(InterviewTitle.CandidateID);
                setTimeout(() => {
                    const rowIndx = this.refDataGrid.instance.getRowIndexByKey(InterviewTitle.CandidateID);
                    this.refDataGrid.instance.editCell(rowIndx, "InterviewTitle");
                }, 200);
                this.setLoading('Save', false);
                return false;
            }
            if(InterviewDate) {
                this.refDataGrid.instance.navigateToRow(InterviewDate.CandidateID);
                setTimeout(() => {
                    const rowIndx = this.refDataGrid.instance.getRowIndexByKey(InterviewDate.CandidateID);
                    this.refDataGrid.instance.editCell(rowIndx, "InterviewDate");
                }, 200);
                this.setLoading('Save', false);
                return false;
            }
            if(Minutes) {
                this.refDataGrid.instance.navigateToRow(Minutes.CandidateID);
                setTimeout(() => {
                    const rowIndx = this.refDataGrid.instance.getRowIndexByKey(Minutes.CandidateID);
                    this.refDataGrid.instance.editCell(rowIndx, "Minutes");
                }, 200);
                this.setLoading('Save', false);
                return false;
            }
            if(InterviewPlace) {
                this.refDataGrid.instance.navigateToRow(InterviewPlace.CandidateID);
                setTimeout(() => {
                    const rowIndx = this.refDataGrid.instance.getRowIndexByKey(InterviewPlace.CandidateID);
                    this.refDataGrid.instance.editCell(rowIndx, "InterviewPlace");
                }, 200);
                this.setLoading('Save', false);
                return false;
            }
            if(InterviewerList) {
                this.refDataGrid.instance.navigateToRow(InterviewerList.CandidateID);
                setTimeout(() => {
                    const rowIndx = this.refDataGrid.instance.getRowIndexByKey(InterviewerList.CandidateID);
                    this.selectedRowIndx = rowIndx;
                    this.refDataGrid.instance.editCell(rowIndx, "InterviewerList");
                }, 200);
                this.setLoading('Save', false);
                return false;
            }
        } else {
            Config.popup.show("INFO", Config.lang("Chua_chon_ung_vien"));
            this.setLoading('Save', false);
            return false;
        }
        } else {
            const validateRules = [
                {
                    name: "InterviewTitle",
                    rules: "isRequired",
                    value: InterviewTitle
                },
                {
                    name: "InterviewType",
                    rules: "isRequired",
                    value: InterviewType
                },
                {
                    name: "InterviewPlace",
                    rules: "isRequired",
                    value: InterviewPlace
                },
                {
                    name: "InterviewDate",
                    rules: "isRequired",
                    value: InterviewDate
                },
                {
                    name: "errorMask",
                    rules: "isRequired",
                    value: InterviewTime
                },
                {
                    name: "Minutes",
                    rules: "isRequired",
                    value: Minutes
                },
                {
                    name: "InterviewerList",
                    rules: "isRequired",
                    value: InterviewerList.length > 0
                },
            ];
            if (SendMailMode) {
                validateRules.push({
                    name: "TemplateMailID",
                    rules: "isRequired",
                    value: TemplateMailID,
                });
            }

            const validateForm = MForm.formValidation(validateRules);
            if (Object.keys(validateForm).length > 0) {
                this.setStateErrorText(validateForm, true);
                this.setLoading('Save', false);
                return false;
            }
        }

        if(isSendMail && SendMailMode !== 0 && this.refEditorContent && !this.refEditorContent.getContent()) {
            const message = Config.lang("Noi_dung_mail_khong_duoc_de_trong");
            Config.popup.show("INFO",message);
            this.setLoading('Save', false);
            return null;
        } else {
            let params = {};
            isSendMail && (dataSource = dataIsUsed)
            if(typeSave) {
                dataSource.forEach(item => {
                    // set lai bang [] truoc khi duyet item.InterviewerList tranh tinh trang double mail
                    InterviewerMail=[];
                    if(item.InterviewType) {
                        const type = this.interviewType.find(val => val.interviewTypeID === item.InterviewType);
                        item.InterviewType = type?.interviewTypeNameEN || "";
                    }
                    if(item.InterviewDate) {
                        let hour = item.InterviewDateText && item.InterviewDateText.split(":");
                        const date = moment(item.InterviewDate);
                        date.set({
                            hour:Number(hour[0]),
                            minute:Number(hour[1]),
                            second:0,
                            millisecond:0,
                        });
                        item.InterviewDate = hour ? date.format("YYYY-MM-DD HH:mm:ss") : null;
                        item.InterviewDay = moment(item.InterviewDate).isValid() ? moment(item.InterviewDate).format('DD/MM/YYYY') : null;
                    }
                    else {
                        item.InterviewDate = null;
                    }
                    if(item.InterviewerList && item.InterviewerList.includes(',')) {
                        item.InterviewerList = item.InterviewerList.split(',');
                        this.getInterViewMail(item.InterviewerList, InterviewerMail);
                    } else if(item.InterviewerList && !item.InterviewerList.includes(',')) {
                        item.InterviewerList = Array.isArray(item.InterviewerList) ? item.InterviewerList : [item.InterviewerList];
                        this.getInterViewMail(item.InterviewerList, InterviewerMail);
                    }
                    else if(item.InterviewerList === ""){
                        item.InterviewerList = [];
                    }
                    if(!item.Minutes) {
                        item.Minutes = null;
                    }
                    if(!item.InterviewerMail) {
                        item.InterviewerMail = InterviewerMail;
                    }
                    if(item.InterviewDateText || item.InterviewDateText === "") {
                        item.InterviewTime = item.InterviewDateText;
                        delete item.InterviewDateText;
                    }
                    if(item.attactments !== []){
                        item.arrAttachment = item.attachments;
                        delete item.attachments;
                    }
                });
                params = {
                    // THANHAN content = nội dung mail, candidate = grid
                    InterviewScheduleID: data?.InterviewScheduleID ?? "",
                    InterviewScheduleName: InterviewScheduleName ?? "",
                    DateFrom: DateFrom ?? null,
                    DateTo: DateTo ?? null,
                    Content: this.refEditorContent ? this.refEditorContent.getContent() : "",
                    candidate: dataSource ? dataSource : "",
                    Subject: TitleMail,
                };
            }
            // THANHAN không savemulti
            else {
                const arrAttachments = this._getAttachments();
                let hour = InterviewTime && InterviewTime.split(":");
                let calculatorDateInterview = (hour &&
                moment(InterviewDate).isValid() ?
                    (moment.utc(InterviewDate).add(hour[0],'h').add(hour[1], 'm').format("YYYY-MM-DD HH:mm:ss")) :
                    moment(InterviewDate).isValid() ?
                        moment.utc(InterviewDate).format("YYYY-MM-DD HH:mm:ss") : null);
                this.getInterViewMail(InterviewerList, InterviewerMail);
                const  interviewTypeName =  this.interviewType.filter(val => val.interviewTypeID === InterviewType);
                params = {
                    Mode: mode === "edit" ? 1 : 0,
                    RecInfoID: dataItemCandidate && dataItemCandidate.RecInfoID ? dataItemCandidate.RecInfoID  : "",
                    CandidateID: dataItemCandidate && dataItemCandidate.CandidateID ? dataItemCandidate.CandidateID  : "",
                    CandidateName: dataItemCandidate && dataItemCandidate.CandidateName ? dataItemCandidate.CandidateName : "",
                    RecpositionName: dataItemCandidate && dataItemCandidate.RecpositionName ? dataItemCandidate.RecpositionName  : "",
                    InterviewerList:  InterviewerList ? JSON.stringify(InterviewerList) : "",
                    NoteInteranal: this.refEditorNoteInteranal ? this.refEditorNoteInteranal.getContent() : "",
                    InterviewType: interviewTypeName && interviewTypeName.length > 0 && interviewTypeName[0].interviewTypeNameEN ? interviewTypeName[0].interviewTypeNameEN : "",
                    CanConfirmStatusID: CanConfirmStatusID ? CanConfirmStatusID : "",
                    SendMailMode: SendMailMode ? SendMailMode : 0,
                    InterviewFileID: InterviewFileID ? InterviewFileID : "",
                    InterviewTitle: InterviewTitle ? InterviewTitle : "",
                    CandidateEmail: dataItemCandidate && dataItemCandidate.CandidateEmail ? dataItemCandidate.CandidateEmail : "",
                    InterviewerMail: InterviewerMail ? JSON.stringify(InterviewerMail) : JSON.stringify([]),
                    Content: this.refEditorContent ? this.refEditorContent.getContent() : "",
                    InterviewDate: calculatorDateInterview,
                    Minutes: Minutes ? String(Minutes) : 0,
                    InterviewPlace: InterviewPlace ? InterviewPlace : "",
                    StageID: dataItemCandidate?.StageID || "",
                    DepartmentName: dataItemCandidate?.DepartmentName || "",
                    RecInfoTitle: dataItemCandidate?.RecInfoTitle || "",
                    StageName: dataItemCandidate?.StageName || "",
                    UserName: dataItemCandidate?.UserName || "",
                    UserFirstname: dataItemCandidate?.UserFirstname || "",
                    UserPhone: dataItemCandidate?.UserPhone || "",
                    UserEmail: dataItemCandidate?.UserEmail || "",
                    InterviewTime: InterviewTime || "",
                    arrAttachment: arrAttachments ? JSON.stringify(arrAttachments) : "",
                    CompanyAddress: dataItemCandidate?.CompanyAddress || "",
                    CompanyName: dataItemCandidate?.CompanyName || "",
                    InterviewDay: moment(calculatorDateInterview).isValid() ? moment(calculatorDateInterview).format('DD/MM/YYYY') : null,
                    // API yêu cầu thêm vào khi modeSave === "save"
                    Subject: TitleMail,
                };
            }

            this.props.w25F2303Actions[modeSave](params, (errors, data) => {
                this.setLoading('Save', false);
                if (errors) {
                    if(params.CandidateEmail === "") {
                        let message = Config.lang("Ung_vien_khong_co_email");
                        Config.popup.show('ERROR', message);
                        return false;
                    }
                    Config.popup.show('ERROR', errors);
                    return false;
                }
                if(data.Status === 1) {
                    if(data.MsgAsk === 0) {
                        let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    } else if (data.MsgAsk === 1) {
                        let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show('YES_NO', message , () => {
                            params = {...params,code: data.code};
                            this._saveData(params, isContinue);
                        });
                    }
                }
                if(data.Status === 0) {
                    if (isContinue) {
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                        this.resetForm();
                    } else {
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                        this.onClose(true);
                        if(changedStage) changedStage(params);
                    }
                }
            });
        }
    };

    _saveData = (params, isContinue) => {
        const { changedStage } = this.props;
        this.props.w25F2303Actions.confirm(params, (errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if(data.Status === 0) {
                if (isContinue) {
                    Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                    this.resetForm();
                } else {
                    Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                    this.onClose(true);
                    if(changedStage) changedStage(params);
                }

            }
        });
    };

    renderFieldInterviewer = (data) => {
        const {classes} = this.props;
        if (data) {
            return (
                <div className={"display_row align-right"} style={{width: "100%", height: '100%', padding: "5px 0"}}>
                    {data && data.map((d, idx) => {
                        return <div key={idx}>
                            <UserImage data={d} width={32}
                                height={32} keyExpr={"UserID"}
                                valueExpr={"UserID"}
                                renderItemHover={user => {
                                    const dateJoined = user ? moment(user.DateJoined).isValid() ? moment(user.DateJoined).format('DD/MM/YYYY') : user.DateJoined : "";
                                    return (
                                        <div className={""}>
                                            {user && user.EmployeeID && <span><b>{user.EmployeeID + " - " + user.EmployeeName}</b><br/></span>}
                                            {user && user.DepartmentName && <span>{Config.lang('Phong_ban') + ': ' + user.DepartmentName}<br/></span>}
                                            {user && user.ProjectName && <span>{Config.lang('Du_an') + ': ' + user.ProjectName}<br/></span>}
                                            {user && user.DutyName && <span>{Config.lang('Chuc_vu') + ': ' +user.DutyName}<br/></span>}
                                            {user && user.DateJoined && <span>{Config.lang('Ngay_vao_lam') + ': ' + dateJoined}</span>}
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    })}
                    <TextBox className={classes.textBox}
                        defaultValue={""}
                        placeholder={data && data.length < 1 ? Config.lang('Nguoi_phong_van') : ""}
                        readOnly={false}
                        onKeyDown={this.onTextBoxKeyDown}
                    />
                </div>
            );
        }
    };

    renderInterviewer = (data) => {
        const dataInterviewer =  Config.getUser({"UserID": data["UserID"]});
        if (data) {
            return (
                <div className={"display_row align-center"}>
                    <UserImage data={data} keyExpr={"UserID"} valueExpr={"UserID"} />
                    <div className="cbo-employee-name">
                        {dataInterviewer && dataInterviewer.EmployeeName && <span>{dataInterviewer.EmployeeName} {dataInterviewer.Email && "(" + dataInterviewer.Email + ")"}<br/></span>}
                        {dataInterviewer && dataInterviewer.DepartmentName && <span>{dataInterviewer.DepartmentName}<br/></span>}
                    </div>
                </div>
            );
        }
    };

    onTextBoxKeyDown = (e) => {
        if (!e || !e.event || !e.event.target) return false;
        const evt = e.event;
        if (evt.keyCode === 8 && !evt.target.value) {
            let ListUserID = JSON.parse(JSON.stringify(this.state.InterviewerList));
            ListUserID.pop();
            setTimeout(() => {
                this.handleChange("UserID", {value: ListUserID});
            }, 300);
        }
    };

    componentDidMount = async () => {
        const { mode, isMultipleSchedule } = this.props;
        let loadMode = mode === "edit" ? 1 : 0;
        await this.loadPermission();
        this.loadCboCandidate(loadMode);
        this.loadListInterview();
        this.loadCboMailTemplate();
        isMultipleSchedule && this.setState({ isShowGrid: true })
        if (mode === "edit") {
            this.loadDataForm();
        }
    };;

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mode, FormID, data} = this.props;
        const { dataCboCandidates } = this.state;
        if(dataCboCandidates && dataCboCandidates.length > 0) {
            if(FormID === "W25F2023") {
                if(!this.setData && mode === "add" && data && data.CandidateID !== "") {
                    const itemCandidate = dataCboCandidates.find(item => item.CandidateID === data.CandidateID);
                    this.setState({
                        CandidateID: itemCandidate?.CandidateID || "",
                        dataItemCandidate : itemCandidate,
                        InterviewTitle:itemCandidate?.InterviewTitle || "",
                        InterviewPlace:itemCandidate?.InterviewPlace || "",
                        StageID:itemCandidate?.StageID || "",
                        StageName:itemCandidate?.StageName || ""
                    }, () => {
                        this.setData = true;
                    });
                }
            }
        }
    }

    setStateErrorText = (value, reset = false) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...reset ? {} : error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };


    handleChange = (key, e) => {
        if (!key) return false;
        const {getCboMailTemplates} = this.props;
        const { dataCboCandidates, error } = this.state;
        const value = e?.target?.value || e?.value;
        switch (key) {
            case "CandidateID":
                this.setState({CandidateID: e.value}, () => {
                    let itemCandidate =  dataCboCandidates.find(val => val.CandidateID === e.value);
                    this.setState({
                        dataItemCandidate : itemCandidate,
                        InterviewTitle:itemCandidate?.InterviewTitle || "",
                        InterviewPlace:itemCandidate?.InterviewPlace || "",
                        StageID:itemCandidate?.StageID || "",
                        StageName: itemCandidate?.StageName || "",
                    });
                });
                break;
            case "interviewTypeID":
                this.setState({InterviewType: e.value});
                break;
            case "InterviewTitle":
                this.setState({InterviewTitle: e.target.value});
                break;
            case "InterviewDate":
                this.setState({InterviewDate: e.value});
                break;
            case "Minutes":
                this.setState({Minutes: e.target.value});
                break;
            case "InterviewPlace":
                this.setState({InterviewPlace: e.target.value});
                break;
            case "SendMailMode":
                if (!e.value && error["TemplateMailID"]) this.setStateErrorText({ TemplateMailID: "" });
                this.setState({ SendMailMode: Number(e.target.value) });
                break;
            case "TemplateMailID":
                this.setState({TemplateMailID: e.value}, () => {
                    let itemMail =  getCboMailTemplates.find(val => val.TemplateMailID === e.value);
                    this.setState({TitleMail: itemMail?.TitleMail || ""}, () => {
                        if(this.refEditorContent) this.refEditorContent.setContent(itemMail?.ContentMail || "")
                    });
                });
                break;
            case "TitleMail":
                this.setState({TitleMail: e.target.value});
                break;
            case "InterviewTime":
                let timeInterview = e.target.value;
                this.setState({InterviewTime: timeInterview}, () => {
                    let checkTime = this.state.InterviewTime.split('');
                    if(!checkTime.some(val => val === "_")) {
                        this.setStateErrorText({errorMask: ""});
                    }
                });
                break;

            case "EmployeeID":
                this.setState({Employee: e.value});
                break;
            case "UserID":
                this.setState({InterviewerList: e.value});
                break;
            case "InterviewScheduleName":
                this.setState({InterviewScheduleName: e.target.value});
                break;
            case "RangePicker":
                let DateFrom = value || "";
                let DateTo = value || "";
                if(Array.isArray(value)){
                    DateFrom = value[0];
                    DateTo = value[1];
                }
                this.setState({DateFrom, DateTo});
                if(DateFrom && DateTo) this.setStateErrorText({"DateFrom": "", "DateTo": ""});
                break;
            default:
                break;
        }
        if(error[key] && value) this.setStateErrorText({[key]: ""});
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    _checkMaskRules = (data) => {
        if(!data) return null;
        return data?.toString().startsWith('2');
    };

    cellRenderSendMailMode = (e) => {
        if(!e) return false;
        const { data } = e;
        return (
            <Dropdown
                style={{margin:0}}
                viewType="none"
                dataSource={this.cboSendMailMode}
                displayExpr={"sendMailModeName"}
                valueExpr={"sendMailModeID"}
                value={data.SendMailMode}
                onChange={lk => {
                    e.component.cellValue(
                        e.rowIndex,
                        "SendMailMode",
                        lk.value
                    );
                }}
            />
        );
    };

    showPopupInterviewerList = (data) => {
        const value = data ? data.replace(/,/g, ';').split(';') : [];
        if(this.popupInterviewerList) {
            this.popupInterviewerList.open(value)
        }
    };

    showPopupAttachments = (data) => {
        this.setState({
            dataAttachmentPopup: data,
            showPopupAttachment: true
        });
    };

    onClosePopupAttachment = () => {
        this.setState({showPopupAttachment: false});
    };

    onEditingStart = (cellData) => {
        if(!cellData) return null;
        const {data, column} = cellData;
        const dataField = column.dataField;
        if(dataField === "InterviewerList") {
            this.showPopupInterviewerList(data[dataField])
        }
        if(dataField === "attachments") {
            this.showPopupAttachments(data[dataField])
        }
    };

    cellRenderPopupAction = (cellData) => {
        if(!cellData) return null;
        const {data, column} = cellData;
        const dataField = column.dataField;
        let onClick = null;
        if(dataField === "InterviewerList") {
            onClick = () => this.showPopupInterviewerList(data[dataField]);
        }
        let listUsers = data?.InterviewerList ?
            (Array.isArray(data?.InterviewerList) ?
                data?.InterviewerList :
                data?.InterviewerList.split(',')) : [];
        listUsers =  listUsers.length > 0 ? Config.getListUser(listUsers, "UserID") : [];
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div className={"pull-left mgr5"}>
                    <IconButton size={'small'} onClick={onClick}>
                        <ArrowDropDownIcon fontSize={"small"}/>
                    </IconButton>
                </div>
                {listUsers && listUsers.length > 0 ?
                    <>
                        <div className={"display_row align-left"}>
                            {
                                listUsers.map((data, index) => {
                                    return (
                                        <UserImage width={"24px"} height={"24px"} allowHover={true} key={index} data={data}/>
                                    )
                                })
                            }
                        </div>
                    </>
                    : ""}
            </div>
        );
    };

    cellRenderPopupAttachment = (cellData) => {
        if(!cellData) return null;
        const {data, column} = cellData;
        const dataField = column.dataField;
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{color: 'blue'}}>{`( ${data[dataField]?.length || 0} )`}</span>
            </div>
        );
    };

    onRowClick = (e) => {
        this.selectedRowIndx = e.rowIndex;
    };

    onSelectedPopup = (data, field) => {
        if(this.refDataGrid) {
            this.refDataGrid.instance.cellValue(this.selectedRowIndx, field, data.join(','))
            this.refDataGrid.instance.saveEditData();
        }
    };

    onChangePopupAttachments = (e) => {
        this.attachmentsPopup = e.allNewAttached ? e.allNewAttached : [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            this.deletedFilePopup = [ ...e.removedAttached];
        }
    };

    onAddAttachments = async () => {
        const dataResUploadFile = await this._uploadFile(this.attachmentsPopup, true);
        const arrAttachments = this._getPopUpAttachments(dataResUploadFile);
        if(this.refDataGrid) {
            this.checkEditData = true;
            this.refDataGrid.instance.cellValue(this.selectedRowIndx, "attachments", arrAttachments);
            this.refDataGrid.instance.saveEditData();
        }
        this._removeCDN();
        this.onClosePopupAttachment();
    };

    _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    _getPopUpAttachments = (file) => {
        const dataFile = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split('.').pop() : ""
            });
        });
        return arrAttachment;
    };

    _removeCDN = () => {
        if (this.deletedFilePopup && this.deletedFilePopup.length > 0) {
            this.deletedFilePopup.forEach(e => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFilePopup = [];
        }
    };

    validateRequired = (key, e) => {
        if(!e) return false;
        const {value} = e;
        switch (key) {
            case "InterviewDate":
                return !(value === ""  || value === null);
            case "Minutes":
                return !(value === ""  || value === null);
            case "InterviewDateText":
                return !(value === ""  || value?.includes("_"));
            case "SendMailMode":
                return !(value === ""  || value === null);
            default:
                break;
        }
    };

    customDataCopy = (e) => {
        if(!e) return false;
        if (e.columnIndex === 9) {
            let dataCell = this.refDataGrid.instance.cellValue(
                this.selectedRowIndx,
                e?.column?.dataField
            );
            dataCell = dataCell.split(',');
            let listUserID = [];
            dataCell.forEach(item => {
                if(item.includes('-')) {
                    listUserID.push(item.split('-').pop())
                }
            });
            return listUserID.join(',');
        } else {
            return this.refDataGrid.instance.cellValue(
                this.selectedRowIndx,
                e?.column?.dataField
            );
        }
    };
    renderHeaderColumns = (e) => {
        if (!e) return false;
        return(
            <HeadClick selectedRange={this.selectedRange}
                       dataHeaderCell={e}
                       allowClick={true}
                       customDataCopy={() => this.customDataCopy(e)}
            />
        )
    };
    calculateCellValue = (data) => {
        let listUsers = data?.InterviewerList ?
                (Array.isArray(data?.InterviewerList) ?
                data?.InterviewerList :
                data?.InterviewerList.split(',')) : [];
        listUsers =  listUsers.length > 0 ? Config.getListUser(listUsers, "UserID") : [];
        listUsers = listUsers.map((item) => item.EmployeeName + "-" + item.UserID);
        return listUsers.join(', ');
    };

    calculateFilterExpression = (filterValue, selectedFilterOperation, target) => {
        if(target === "filterRow") {
            return [this.calculateCellValue, selectedFilterOperation || 'contains', filterValue];
        }
    };

    calculateCellSendMailModeValue = (data) => {
        const itemMail = this.cboSendMailMode.find(item => item.sendMailModeID === data?.SendMailMode);
        return itemMail?.sendMailModeName;
    };

    calculateSendMailModeFilterExpression = (filterValue, selectedFilterOperation, target) => {
        if(target === "filterRow") {
            return [this.calculateCellSendMailModeValue, selectedFilterOperation || 'contains', filterValue];
        }
    };

    setGridRef = (ref) =>{
        this.refDataGrid = ref;
    }

    render() {
        const { open, getListInterviews, mode, getCboMailTemplates, FormID, disableIconSave } = this.props;
        const {
            uploading,
            error,
            loading,
            dataOldAttachments,
            CandidateID,
            InterviewType,
            InterviewDate,
            Minutes,
            disabled,
            SendMailMode,
            InterviewerList,
            loadingCboCandidate,
            loadingListInterview,
            InterviewTitle,
            InterviewPlace,
            InterviewTime,
            TemplateMailID,
            TitleMail,
            loadingAttachments,
            loadingCboMailTemplate,
            isShowGrid,
            dataCboCandidates,
            showPopupListMember,
            CandidateName,
            showPopupAttachment,
            dataAttachmentPopup,
            selectedRowKeys,
            iPermission,
            dataGridCandidates,
            loadingListCandidate,
            StageName,
            InterviewScheduleName,
            NumSendMail,
            DateFrom,
            DateTo,
            loadingSave
        } = this.state;

        let ListUserID = getListInterviews ? getListInterviews : "";
        ListUserID = ListUserID
            ? Array.isArray(ListUserID)
                ? ListUserID
                : getListInterviews.replace(/,/g, ";").split(";")
            : [];

        let dataListInterview = InterviewerList.length > 0 ? Config.getListUser(InterviewerList, "UserID") : [];
        const hiddenToggle = !(FormID === "W25F2023" || (FormID === "W25F2033" && mode === "edit"));

        const columnInterviewerList = [
            {
                caption: Config.lang("Ma"),
                dataField: "UserID"
            },
            {
                caption: Config.lang("Ten"),
                dataField: "EmployeeName"
            }
        ];

        const dataRadio = [
            {
                value: 3,
                label: Config.lang("Ung_vien_va_nguoi_phong_van"),
            },
            {
                value: 1,
                label: Config.lang("Chi_ung_vien"),
            },
            {
                value: 2,
                label: Config.lang("Chi_nguoi_phong_van"),
            },
            {
                value: 0,
                label: Config.lang("Khong_gui_email"),
            },
        ];

        const checkRuleInterviewTime = InterviewTime && this._checkMaskRules(InterviewTime);
        return (
            <React.Fragment>
                <PopupSelect2
                    ref={ref => (this.popupInterviewerList = ref)}
                    typePaging={"normal"}
                    mode={"multiple"}
                    multiple
                    allowSearch={false}
                    keyExpr={"UserID"}
                    title={Config.lang("Nguoi_PV")}
                    open={showPopupListMember}
                    dataSource={ListUserID || []}
                    column={columnInterviewerList}
                    onChosen={data => {
                        if (isShowGrid) {
                            this.onSelectedPopup(data, "InterviewerList");
                        } else {
                            this.handleChange("UserID", { value: data });
                        }
                    }}
                    onClose={() => this.setState({ showPopupListMember: false })}
                />
                <Modal open={open} fullWidth={true} onClose={()=>this.onClose(false)}>
                    <PopupAttachment
                        open={showPopupAttachment}
                        data={dataAttachmentPopup}
                        label={Config.lang("Dinh_kem")}
                        onChange={this.onChangePopupAttachments}
                        onClose={this.onClosePopupAttachment}
                        onAdd={this.onAddAttachments}
                    />
                    <ModalHeader disableTypography title={Config.lang("Tao_lich_phong_van")}>
                        {hiddenToggle && (
                            <div className={"display_row align-center"}>
                                <Toggle
                                    checked={isShowGrid}
                                    label={Config.lang("Lap_lich_hang_loat")}
                                    onChange={e => {
                                        if(error["TemplateMailID"]) this.setStateErrorText({ TemplateMailID: "" });
                                        this.setState({ isShowGrid: e.target.checked }, () => {
                                            if (this.state.isShowGrid) {
                                                this.loadGridCandidate();
                                            }
                                        })
                                    }}
                                />
                            </div>
                        )}
                    </ModalHeader>
                    <ModalBody>
                        <Loading visible={loadingSave}/>
                        <Row>
                            {!isShowGrid  ? (
                                <>
                                    <Col xs={12} sm={4} md={4} lg={4} className={"display_row align-center"}>
                                        <Label>
                                            {Config.lang("Ung_vien")}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        {mode === "edit" ? (
                                            <TextInput
                                                readOnly
                                                value={CandidateName}
                                                placeholder={Config.lang("Ung_vien")}
                                            />
                                        ) : (
                                            <Dropdown
                                                dataSource={dataCboCandidates}
                                                displayExpr={"CandidateName"}
                                                valueExpr={"CandidateID"}
                                                readOnly={mode === "edit"}
                                                iconExpr={{ key: 'CadidatePictureURL', prefix: Config.getCDNPath() }}
                                                renderSelectedItem={e => (e.data.CandidateName)}
                                                showClearButton={true}
                                                loading={loadingCboCandidate}
                                                value={CandidateID}
                                                viewType={"underlined"}
                                                placeholder={Config.lang("Ung_vien")}
                                                onChange={e =>
                                                    this.handleChange("CandidateID", e)
                                                }
                                            />
                                        )}
                                    </Col>
                                    <Col xs={12} sm={4} md={4} lg={4} className={"display_row align-center"}>
                                        <Label>
                                            {Config.lang("Giai_doan")}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <TextInput
                                            readOnly
                                            value={StageName}
                                            placeholder={Config.lang("Giai_doan")}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} md={4} lg={4} className={"display_row align-center"}>
                                        <Label required>
                                            {Config.lang("Loai_phong_van")}&nbsp;
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <Dropdown
                                            error={error?.["InterviewType"]}
                                            dataSource={this.interviewType}
                                            displayExpr={Config.language ? "interviewTypeNameVI" : "interviewTypeNameEN"}
                                            valueExpr={"interviewTypeID"}
                                            value={InterviewType}
                                            viewType={"underlined"}
                                            placeholder={Config.lang("Loai_phong_van")}
                                            onChange={e =>
                                                this.handleChange("interviewTypeID", e)
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} md={4} lg={4} className={"display_row align-center"}>
                                        <Label required>
                                            {Config.lang("Tieu_de_phong_van")}&nbsp;
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <TextInput
                                            error={error?.["InterviewTitle"]}
                                            variant={"standard"}
                                            value={InterviewTitle}
                                            onChange={e =>
                                                this.handleChange("InterviewTitle", e)
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} md={4} lg={4} className={"display_row align-center"}>
                                        <Label required>
                                            {Config.lang("Thoi_gian_phong_van")}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <Row>
                                            <Col xs={12} sm={4} md={4} lg={4}>
                                                <DatePicker
                                                    error={error?.["InterviewDate"]}
                                                    viewType={"underlined"}
                                                    placeholder={"dd/mm/yyyy"}
                                                    clearAble
                                                    value={InterviewDate}
                                                    onChange={e =>
                                                        this.handleChange(
                                                            "InterviewDate",
                                                            e
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col xs={12} sm={4} md={4} lg={4}>
                                                <InputMask
                                                    mask={"12:34"}
                                                    maskChar={"_"}
                                                    alwaysShowMask={true}
                                                    formatChars={{
                                                        1: "[0-2]",
                                                        2: checkRuleInterviewTime
                                                            ? "[0-3]"
                                                            : "[0-9]",
                                                        3: "[0-5]",
                                                        4: "[0-9]",
                                                    }}
                                                    className={'mgb0 mgt1x'}
                                                    onBlur={() => {
                                                        if (InterviewTime) {
                                                            if (
                                                                InterviewTime.split(
                                                                    ""
                                                                ).some(
                                                                    val =>
                                                                        val === "_"
                                                                )
                                                            ) {
                                                                this.setStateErrorText(
                                                                    {
                                                                        errorMask:
                                                                            Config.lang(
                                                                                "Ban_phai_nhap_dung_dinh_dang_vd_11:11"
                                                                            ),
                                                                    }
                                                                );
                                                            } else {
                                                                this.setStateErrorText(
                                                                    {
                                                                        errorMask:
                                                                            "",
                                                                    }
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    value={InterviewTime || ""}
                                                    onChange={e =>
                                                        this.handleChange(
                                                            "InterviewTime",
                                                            e
                                                        )
                                                    }
                                                >
                                                    {props => (
                                                        <TextFieldM
                                                            {...props}
                                                            fullWidth
                                                            variant={"standard"}
                                                        />
                                                    )}
                                            </InputMask>
                                                <HelperText>
                                                    {error?.["errorMask"]}
                                                </HelperText>
                                            </Col>
                                            <Col xs={12} sm={4} md={4} lg={4}>
                                                <NumberInput
                                                    error={error && error["Minutes"]}
                                                    required={true}
                                                    value={Minutes}
                                                    min={0}
                                                    endIcon={<Typography style={{whiteSpace: 'pre'}}>{Config.lang("Phut")}</Typography>}
                                                    onChange={e =>
                                                        this.handleChange("Minutes", e)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={4} md={4} lg={4} className={"display_row align-center"}>
                                        <Label required>
                                            {Config.lang("Dia_diem_phong_van")}&nbsp;
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <TextInput
                                            error={error?.["InterviewPlace"]}
                                            value={InterviewPlace}
                                            onChange={e =>
                                                this.handleChange("InterviewPlace", e)
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} md={4} lg={4} className={"display_row align-center"}>
                                        <Label required>
                                            {Config.lang("Nguoi_phong_van")}&nbsp;
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <div
                                            onClick={() => {
                                                if (this.popupInterviewerList) {
                                                    this.popupInterviewerList.open(InterviewerList);
                                                }}
                                            }
                                        >
                                            <Dropdown
                                                error={error && error["InterviewerList"]}
                                                valueExpr={"UserID"}
                                                displayExpr={"EmployeeName"}
                                                value={dataListInterview}
                                                valueObjectDefault={dataListInterview}
                                                loading={loadingListInterview}
                                                readOnly
                                                multiple
                                                renderSelectedItem={({data, index}) => {
                                                    return <UserImage key={index} data={data} allowHover width={32} height={32}/>
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={4} md={4} lg={4}>
                                        <Label required>
                                            {Config.lang("Nguoi_nhan_email")}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        {dataRadio.map((r, index)=>{
                                            return <Radio key={index} {...r}
                                                          checked={r.value === SendMailMode}
                                                          onChange={e=>this.handleChange("SendMailMode", e)}
                                                          className={"mgb2x"}
                                            />
                                        })}
                                        <HelperText>{error?.["SendMailMode"]}</HelperText>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col xs={8} sm={8} md={8} lg={8}>
                                        <TextInput
                                            label={Config.lang("Dien_giai")}
                                            required
                                            value={InterviewScheduleName}
                                            error={error && error["InterviewScheduleName"]}
                                            onChange={e => this.handleChange("InterviewScheduleName", e)}
                                        />
                                    </Col>

                                    <Col xs={4}>
                                        <DateRangePicker
                                            clearAble
                                            required
                                            controls
                                            error={error?.["DateFrom"] || error?.["DateTo"]}
                                            value={[DateFrom, DateTo]}
                                            label={Config.lang("Ngay_phong_van")}
                                            onChange={e => this.handleChange("RangePicker", e)}
                                            placeholder={"DD/MM/YYYY - DD/MM/YYYY"}
                                            returnFormat={"YYYY-MM-DD"}
                                        />
                                    </Col>

                                    <Col xs={12}>
                                        <GridCandidate
                                            setGridRef={this.setGridRef}
                                            dataSource={dataGridCandidates || []}
                                            keyExpr={"CandidateID"}
                                            dataGridLoading={loadingListCandidate}
                                            selected={selectedRowKeys}
                                            typePaging={"normal"}
                                            editing={{
                                                mode: "cell",
                                                refreshMode: "reshape",
                                                texts: {
                                                    confirmDeleteMessage: "",
                                                },
                                                allowUpdating: true,
                                                startEditAction: "dblClick",
                                            }}
                                            onCellSelectionChanged={e => {
                                                if (
                                                    e.selectedRange &&
                                                    e.selectedRange.rowType !== "header"
                                                ) {
                                                    this.selectedRange = e.selectedRange;
                                                }
                                            }}
                                            onRowClick={this.onRowClick}
                                            onEditingStart={this.onEditingStart}
                                            onEditorPreparing={e => {
                                                if (
                                                    e.parentType === "filterRow" &&
                                                    e.dataField === "InterviewDateText"
                                                ) {
                                                    e.editorOptions.onValueChanged = val => {
                                                        if (val.value && val.value === "__:__") {
                                                            e.setValue("");
                                                        } else {
                                                            e.setValue(val.value);
                                                        }
                                                    };
                                                }
                                            }}
                                            onRowValidating={e => {
                                                if (this.checkEditData) {
                                                    e.isValid = true;
                                                    this.checkEditData = false;
                                                }
                                            }}
                                        >
                                            <Column
                                                caption={""}
                                                dataField={"IsUsed"}
                                                dataType={"boolean"}
                                                visible={false}
                                            />
                                            <Column
                                                caption={Config.lang("Ung_vien")}
                                                dataField={"CandidateName"}
                                                allowEditing={false}
                                                fixed={true}
                                                width={200}
                                            />
                                            <Column
                                                caption={Config.lang("Giai_doan")}
                                                dataField={"StageName"}
                                                fixed={true}
                                                allowEditing={false}
                                                width={200}
                                            />
                                            <Column
                                                caption={Config.lang("Loai_phong_van")}
                                                dataField={"InterviewType"}
                                                lookup={{
                                                    dataSource: this.interviewType,
                                                    valueExpr: "interviewTypeID",
                                                    displayExpr: e => {
                                                        if (!e) return;
                                                        let name;
                                                        if (Config.language === "84") {
                                                            name = e.interviewTypeNameVI;
                                                        } else {
                                                            name = e.interviewTypeNameEN;
                                                        }
                                                        return name;
                                                    },
                                                }}
                                                editorOptions={{
                                                    placeholder: Config.lang("Loai_phong_van") + "...",
                                                }}
                                                headerCellRender={this.renderHeaderColumns}
                                                allowEditing={true}
                                                width={200}
                                            >
                                                <RequiredRule message="Giá trị bắt buộc nhập" />
                                            </Column>
                                            <Column
                                                caption={Config.lang("Tieu_de_phong_van")}
                                                dataField={"InterviewTitle"}
                                                allowEditing={true}
                                                width={250}
                                            />
                                            <Column
                                                caption={Config.lang("Ngay_phong_van")}
                                                dataType={"date"}
                                                format={"dd/MM/yyyy"}
                                                alignment={"left"}
                                                width={200}
                                                editorOptions={{
                                                    placeholder: "DD/MM/YYYY",
                                                }}
                                                dataField={"InterviewDate"}
                                                headerCellRender={this.renderHeaderColumns}
                                                allowCopying={true}
                                                allowEditing={true}
                                            >
                                                <CustomRule
                                                    validationCallback={e =>
                                                        this.validateRequired("InterviewDate", e)
                                                    }
                                                    message="Giá trị bắt buộc nhập"
                                                />
                                            </Column>
                                            <Column
                                                caption={Config.lang("Gio_phong_van")}
                                                dataField={"InterviewDateText"}
                                                alignment={"left"}
                                                headerCellRender={this.renderHeaderColumns}
                                                editorOptions={{
                                                    mask: "AB:CD",
                                                    maskRules: {
                                                        A: /[0-9_]/,
                                                        B: /[0-9_]/,
                                                        C: /[0-5_]/,
                                                        D: /[0-9_]/,
                                                    },
                                                    onFocusOut: e => {
                                                        if (!e) return null;
                                                        let { value } = e.event.target;
                                                        if (value === "__:__") {
                                                            value = ``;
                                                            return;
                                                        }
                                                        if (value && parseInt(value.split(":")[0]) > 24) {
                                                            value = `24:00`;
                                                        }
                                                        e.component.option("value", value);
                                                    },
                                                    onInput: e => {
                                                        if (!e) return null;
                                                        let { value } = e.event.target;
                                                        e.component.option("value", value);
                                                        if (value.split("_")[0] === "") {
                                                            value = value.replace(value, "");
                                                        }

                                                        if (value.split("_").length <= 3) {
                                                            value = value.replace(/_/g, "0");
                                                        }

                                                        if (
                                                            value.split("_").length === 4 &&
                                                            value[0] !== "_"
                                                        ) {
                                                            const firstLetter = parseInt(value[0]);
                                                            value =
                                                                (firstLetter >= 3
                                                                    ? `0${firstLetter}`
                                                                    : `${firstLetter}0`) + `:00`;
                                                        }
                                                        e.component.option("value", value);
                                                    },
                                                }}
                                                width={150}
                                                allowEditing={true}
                                            >
                                                <CustomRule
                                                    validationCallback={e =>
                                                        this.validateRequired("InterviewDateText", e)
                                                    }
                                                    message="Giá trị bắt buộc nhập"
                                                />
                                            </Column>
                                            <Column
                                                caption={Config.lang("phut")}
                                                headerCellRender={this.renderHeaderColumns}
                                                dataField={"Minutes"}
                                                dataType={"number"}
                                                allowEditing={true}
                                                width={150}
                                                alignment={"left"}
                                            >
                                                <CustomRule
                                                    validationCallback={e =>
                                                        this.validateRequired("Minutes", e)
                                                    }
                                                    message="Giá trị bắt buộc nhập"
                                                />
                                            </Column>

                                            <Column
                                                caption={Config.lang("Dia_diem_phong_van")}
                                                dataField={"InterviewPlace"}
                                                headerCellRender={this.renderHeaderColumns}
                                                allowEditing={true}
                                                width={200}
                                            />
                                            <Column
                                                caption={Config.lang("Nguoi_phong_van")}
                                                dataField={"InterviewerList"}
                                                cellRender={this.cellRenderPopupAction}
                                                headerCellRender={this.renderHeaderColumns}
                                                calculateCellValue={this.calculateCellValue}
                                                calculateFilterExpression={this.calculateFilterExpression}
                                                allowEditing={true}
                                                width={200}
                                            />
                                            <Column
                                                caption={Config.lang("Nguoi_nhan_email")}
                                                alignment={"center"}
                                                dataField={"SendMailMode"}
                                                cellRender={this.cellRenderSendMailMode}
                                                calculateFilterExpression={
                                                    this.calculateSendMailModeFilterExpression
                                                }
                                                width={250}
                                                headerCellRender={this.renderHeaderColumns}
                                            >
                                            </Column>
                                            <Column
                                                caption={Config.lang("Ghi_chep_noi_bo")}
                                                dataField={"NoteInteranal"}
                                                headerCellRender={this.renderHeaderColumns}
                                                allowEditing={true}
                                                width={200}
                                            />
                                            <Column
                                                caption={Config.lang("Dinh_kem")}
                                                dataField={"attachments"}
                                                cellRender={this.cellRenderPopupAttachment}
                                                width={200}
                                            />

                                            <Column
                                                caption={Config.lang("So_mail_da_gui")}
                                                dataField={"NumSendMail"}
                                                width={200}
                                            />
                                        </GridCandidate>
                                    </Col>
                                </>
                            )}

                            <Col xs={12} sm={4} md={4} lg={4}>
                                <Dropdown
                                    dataSource={getCboMailTemplates}
                                    error={error?.["TemplateMailID"]}
                                    displayExpr={"TemplateMailName"}
                                    valueExpr={"TemplateMailID"}
                                    value={TemplateMailID}
                                    viewType={"underlined"}
                                    loading={loadingCboMailTemplate}
                                    placeholder={Config.lang("Chon_mau_mail_gui_cho_ung_vien")}
                                    onChange={e => this.handleChange("TemplateMailID", e)}
                                />
                            </Col>
                            {!isShowGrid && (
                                <Col xs={12} sm={4} md={4} lg={4} className={"display_row align-center"}>
                                    <Typography>{`${Config.lang(
                                        "So_mail_da_gui"
                                    )}: ${NumSendMail}`}</Typography>
                                </Col>
                            )}
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    placeholder={Config.lang("Tieu_de_mail")}
                                    viewType={"outlined"}
                                    value={TitleMail}
                                    onChange={e => this.handleChange("TitleMail", e)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Label>
                                    {Config.lang("Email_gui_den_ung_vien")}
                                </Label>
                                <Editor
                                    init={{
                                        height: 360,
                                        plugins: "advlist lists link image",
                                        toolbar:
                                            "styleselect | bold italic forecolor | bullist numlist | link image| removeformat",
                                        menubar: false,
                                    }}
                                    onInit={(e, editor) => (this.refEditorContent = editor)}
                                />
                            </Col>
                            {!isShowGrid &&
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Label>
                                        {Config.lang("Ghi_chep_noi_bo")}
                                    </Label>
                                    <Editor
                                        init={{
                                            height: 360,
                                            plugins: "advlist lists link image",
                                            toolbar:
                                                "styleselect | bold italic forecolor | bullist numlist | link image| removeformat",
                                            menubar: false,
                                        }}
                                        onInit={(e, editor) => (this.refEditorNoteInteranal = editor)}
                                    />
                                </Col>
                            }
                            {!isShowGrid &&
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Attachments
                                        ref={ref => (this.attRef = ref)}
                                        showButton={true}
                                        files={dataOldAttachments}
                                        disabled={disabled || loading || uploading || loadingAttachments}
                                        maxLength={5}
                                        uploading={loading}
                                        onUploading={this.onUploading}
                                        onChanged={this.onChangeAttachments}
                                    />
                                </Col>
                            }
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {isShowGrid && (
                                <Button
                                    text={Config.lang("Gui_mail")}
                                    startIcon={"mail"}
                                    viewType={"filled"}
                                    color={"info"}
                                    disabled={loadingSave}
                                    style={{ textTransform: "uppercase" }}
                                    className={'mgr1x'}
                                    onClick={() => this.onAdd(false, isShowGrid, true)}
                                />
                        )}
                            <Button
                                text={Config.lang("Luu")}
                                startIcon={"save"}
                                viewType={"filled"}
                                color={"info"}
                                disabled={loadingSave}
                                style={{ textTransform: "uppercase" }}
                                className={'mgr1x'}
                                onClick={() => this.onAdd(false, isShowGrid)}
                            />
                            <Button
                                text={Config.lang("Luu_va_nhap_tiep")}
                                startIcon={"save"}
                                viewType={"filled"}
                                color={"info"}
                                disabled={iPermission <= 1 || mode === "edit" || disableIconSave || loadingSave}
                                style={{ textTransform: "uppercase" }}
                                className={'mgr1x'}
                                onClick={() => this.onAdd(true, isShowGrid)}
                            />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

W25F2303.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onChosen: PropTypes.func,
    RecInfoID: PropTypes.string,
    data: PropTypes.object,
    mode: PropTypes.string,
    changedStage: PropTypes.func,
    disableIconSave: PropTypes.bool,
};

export default compose(connect((state) => ({
    getAttachmentsByTransID: state.general.getAttachmentsByTransID,
    getListInterviews: state.W25F2303.getListInterviews,
    getCboMailTemplates: state.W25F2303.getCboMailTemplate
}), (dispatch) => ({
    w25F2303Actions: bindActionCreators(W25F2303Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W25F2303);